import { AxiosError } from 'axios';
import api from '../../../services/api';
import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { useForm, SubmitHandler } from 'react-hook-form';
import InputLabel from '../../../rxlib/components/input-label';
import Redirecionar from '../../../rxlib/components/redirecionar';
import { tratarErroApi } from '../../../rxlib/services/utilitarios';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import ButtonsCrud from '../../../rxlib/components/buttons/buttons-crud';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import { ModalPrimary } from '../../../rxlib/components/modal/modal-primary';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';
import { ApiError, DocumentoAcesso, DocumentoAcessoCadastroProps } from '../../../services/tipos';

function DocumentoAcessoCadastro(props: DocumentoAcessoCadastroProps) {
    const [salvo, setSalvo] = useState<boolean>(false);
    const [nomeUsuario, setNomeUsuario] = useState<string>('');
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showPrimary, setShowPrimary] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [messagePrimary, setMessagePrimary] = useState<string[]>([]);

    const [documentoAcesso, setDocumentoAcesso] = useState<DocumentoAcesso>({
        id: '',
        idUsuario: '',
        documento: '',
    });

    const { register, handleSubmit } = useForm<DocumentoAcesso>();

    const handleHideWarning = () => setShowWarning(false);

    const handleHidePrimary = () => {
        setShowPrimary(false);
        setSalvo(true);
    }

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Usuários', link: '/usuario' },
        { texto: 'Documentos acesso usuário', link: `/usuario/documentoacesso/${props.match.params.idUsuario}` },
        { texto: props.match.params.action ? 'Visualização' : props.match.params.id ? 'Edição' : 'Novo', link: '' },
    ];

    const temPermissao = props.match.params.action
        ? usuarioTemPermissao(token, 'documento_acesso_usuario_visualizar')
        : props.match.params.id
            ? usuarioTemPermissao(token, 'documento_acesso_usuario_editar')
            : usuarioTemPermissao(token, 'documento_acesso_usuario_novo');

    useEffect(() => {
        if (temPermissao) {
            if (props.match.params.id) {
                api.get(`/DocumentoAcessoUsuario/${props.match.params.id}`)
                    .then(response => {
                        setDocumentoAcesso({
                            id: response.data.id,
                            idUsuario: response.data.idUsuario,
                            documento: response.data.documento,
                        });
                    }).catch((error: AxiosError<ApiError>) => {
                        tratarErro(error);
                    });
            }

            api.get(`/Usuario/${props.match.params.idUsuario}`)
                .then(response => {
                    setNomeUsuario(response.data.nome);
                }).catch((error: AxiosError<ApiError>) => {
                    tratarErro(error);
                });
        }
    }, [props.match.params.id, props.match.params.idUsuario, temPermissao]);

    const onSubmit: SubmitHandler<DocumentoAcesso> = data => {
        setCarregando(true);
        props.match.params.id
            ? editar(data)
            : salvar(data);
    }

    function salvar(data: DocumentoAcesso) {
        api.post('/DocumentoAcessoUsuario', data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function editar(data: DocumentoAcesso) {
        api.put('/DocumentoAcessoUsuario', data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function informarSucesso() {
        setMessagePrimary(['Documento para acesso do usuário salvo com sucesso.']);
        setShowPrimary(true);
    }

    function tratarErro(error: AxiosError<ApiError>) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, 'Não foi possível salvar o documento para acesso do usuário: '));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <form onSubmit={handleSubmit(onSubmit)} className='rxlib-form'>
                    <div className='container-fluid'>
                        <div className='row px-1'>
                            <div className='col-12'>
                                <h6>{props.match.params.action ? 'Visualizar' : props.match.params.id ? 'Editar' : 'Novo'} documento para acesso do usuário</h6>
                            </div>
                        </div>
                        {
                            props.match.params.id
                                ? <div className='row px-1'>
                                    <div className='col-12 mt-1'>
                                        <InputLabel
                                            name='id'
                                            type='text'
                                            id='inputId'
                                            maxLength={50}
                                            readOnly={true}
                                            label='Código:'
                                            autoFocus={false}
                                            defaultValue={documentoAcesso.id}
                                            referencia={register({ required: true })}
                                            placeholder='Código do documento para acesso do usuário' />
                                    </div>
                                </div>
                                : ''
                        }
                        <div className='row px-1 d-none'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    type='text'
                                    maxLength={50}
                                    readOnly={true}
                                    name='idUsuario'
                                    autoFocus={false}
                                    id='inputIdUsuario'
                                    label='Código do usuário:'
                                    placeholder='Código do usuário'
                                    referencia={register({ required: true })}
                                    defaultValue={props.match.params.idUsuario} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    type='text'
                                    maxLength={50}
                                    name='usuario'
                                    readOnly={true}
                                    id='inputEmail'
                                    label='Usuário:'
                                    autoFocus={false}
                                    placeholder='Usuário'
                                    defaultValue={nomeUsuario}
                                    referencia={React.createRef()} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    type='text'
                                    maxLength={50}
                                    name='documento'
                                    autoFocus={false}
                                    label='Documento:'
                                    id='inputDocumento'
                                    defaultValue={documentoAcesso.documento}
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'}
                                    placeholder='Documentos para acesso do usuário' />
                            </div>
                        </div>
                        <ButtonsCrud
                            carregando={carregando}
                            styleButton='btn-rxlib'
                            visualizar={props.match.params.action === 'view'}
                            linkCancelarVoltar={`/usuario/documentoacesso/${props.match.params.idUsuario}`} />
                    </div>
                </form>
                <ModalWarning
                    show={showWarning}
                    message={messageWarning}
                    onHide={handleHideWarning} />
                <ModalPrimary
                    show={showPrimary}
                    message={messagePrimary}
                    onHide={handleHidePrimary} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <Redirecionar
                    se={salvo}
                    para={`/usuario/documentoacesso/${props.match.params.idUsuario}`} />
            </RxlibLayout>
        </>
    );
}

export default DocumentoAcessoCadastro;