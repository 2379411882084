import { AxiosError } from 'axios';
import api from '../../../services/api';
import { useState, useEffect } from 'react';
import LicencaDesktopExclusao from '../exclusao';
import { useAppSelector } from '../../../store/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../../rxlib/components/buttons/button';
import InputLabel from '../../../rxlib/components/input-label';
import { ApiError, ListagemProps } from '../../../services/tipos';
import { tratarErroApi } from '../../../rxlib/services/utilitarios';
import { Listagem } from '../../../rxlib/components/layout/listagem';
import ButtonLink from '../../../rxlib/components/buttons/button-link';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import DataPadraoExpiracaoLicenca from '../data-padrao-expiracao-licenca';
import { ModalHistoricoAcessoLicenca } from '../historico-acesso-licenca';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { SelectLabel } from '../../../rxlib/components/select/select-label';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';
import { AcoesTabela, ConfiguracoesTabela } from '../../../rxlib/components/table';
import { SelectLabelAsync } from '../../../rxlib/components/select/select-label-async';

interface Filtros {
    campo: string;
    valor: string;
    idProduto: string;
}

const campos: any = [
    { Valor: '', Campo: 'Selecione o campo para filtrar' },
    { Valor: 'Nome', Campo: 'Nome' },
    { Valor: 'CnpjBase', Campo: 'CNPJ Base' }
];

function LicencaDesktopListagem(props: ListagemProps) {
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showExclusao, setShowExclusao] = useState<boolean>(false);
    const [licencasDesktop, setLicencaDesktops] = useState<[{}]>([{}]);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [idLicencaDesktop, setIdLicencaDesktop] = useState<string>('');
    const [campoSelecionado, setCampoSelecionado] = useState<string>('');
    const [token] = useState<string>(useAppSelector(state => state.token));
    const [realizarConsulta, setRealizarConsulta] = useState<boolean>(true);
    const [showModalHistoricoAcessoLicenca, setShowModalHistoricoAcessoLicenca] = useState<boolean>(false);
    const [showModalAlteraDataPadraoLicenca, setShowModalAlteraDataPadraoLicenca] = useState<boolean>(false);

    const [filtros, setFiltros] = useState<Filtros>({
        campo: '',
        valor: '',
        idProduto: '',
    });

    const { register, handleSubmit } = useForm<Filtros>();

    const handleHide = () => setShowWarning(false);

    const handleHideExclusao = () => setShowExclusao(false);

    const handleHideModalAlteraDataPadraoLicenca = () => {
        setRealizarConsulta(true);
        setShowModalAlteraDataPadraoLicenca(false);
    };

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Licenças desktop', link: '' },
    ];

    const configuracoesTabela: ConfiguracoesTabela = {
        mensagemPadrao: 'Não foram encontrados resultados para a consulta.',
        colunas: [
            { nome: 'Nome', campo: 'Nome', tipo: 'string' },
            { nome: 'CNPJ Base', campo: 'CnpjBase', tipo: 'string' },
            { nome: 'Expiração', campo: 'Expiracao', tipo: 'date' },
            { nome: 'Personalizada', campo: 'UtilizaDataExpiracaoPersonalizada', tipo: 'boolean' },
            { nome: 'Venda', campo: 'ClienteProvenienteVenda', tipo: 'boolean' },
            { nome: 'Produto', campo: 'Nome', entidadePai: 'Produto', tipo: 'string' },
        ],
    }

    const temPermissao: boolean = usuarioTemPermissao(token, 'licenca_desktop_visualizar');
    const linkNovo: string = usuarioTemPermissao(token, 'licenca_desktop_novo') ? '/licencadesktop/novo' : '';
    const linkEdicao: string = usuarioTemPermissao(token, 'licenca_desktop_editar') ? '/licencadesktop/editar' : '';
    const handleExclusao: ((id: string) => void) | undefined = usuarioTemPermissao(token, 'licenca_desktop_excluir') ? handleExcluir : undefined;

    const temPermissaoDataPadrao: boolean = usuarioTemPermissao(token, 'licenca_desktop_visualizar');

    useEffect(() => {
        if ((temPermissao) && (realizarConsulta)) {
            setCarregando(true);

            let filtro: string = '';
            let request: string = '/OData/LicencaDesktop?completa=true&$expand=Produto';

            if ((filtros.campo !== '') && (filtros.valor !== '')) {
                filtro += `contains(toupper(${filtros.campo}),toupper('${filtros.valor}'))`;
            }

            if ((filtro !== '') && (filtros.idProduto !== '')) {
                filtro += ' and ';
            }

            if (filtros.idProduto !== '') {
                filtro += `idProduto eq ${filtros.idProduto}`;
            }

            request += (filtro !== '')
                ? '&filter=' + filtro
                : '';

            api.get(request)
                .then(response => {
                    setTimeout(() => {
                        setLicencaDesktops(response.data.value);
                        setRealizarConsulta(false);
                        setCarregando(false);
                    }, 250);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                    setShowWarning(true);
                });
        }
    }, [temPermissao, filtros, realizarConsulta]);

    function obterAcoesPersonalizadas(): AcoesTabela[] {
        const acoesPersonalizadas: AcoesTabela[] = [];

        if (usuarioTemPermissao(token, 'historico_acesso_licenca_visualizar')) {
            acoesPersonalizadas.push({
                nome: "Histórico acesso licença",
                iconeClassName: "fas fas fa-list-alt me-3",
                campo: "Id",
                onClick: (id: string) => {
                    setIdLicencaDesktop(id);
                    setShowModalHistoricoAcessoLicenca(true);
                },
            });
        }

        return acoesPersonalizadas;
    }

    const onSubmit: SubmitHandler<Filtros> = data => {
        setFiltros({
            campo: data.campo,
            valor: data.valor,
            idProduto: data.idProduto,
        });
        setRealizarConsulta(true);
    }

    function handleExcluir(id: string) {
        setIdLicencaDesktop(id);
        setShowExclusao(true);
    }

    function tratarErro(error: AxiosError<ApiError>, mensagem: string) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, mensagem));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='container-fluid'>
                        <div className='row px-1'>
                            <div className='col-12'>
                                <h6>Listagem de licenças desktop</h6>
                            </div>
                        </div>
                        <div className='row px-1 mt-2'>
                            <div className='col-2'>
                                <SelectLabel
                                    action=''
                                    foco='sim'
                                    name='campo'
                                    itens={campos}
                                    label='Campo:'
                                    required='nao'
                                    id='selectCampo'
                                    ariaLabel='campo'
                                    campoExibicaoRegistro='Campo'
                                    valorSelecionado={filtros.campo}
                                    campoIdentificadorRegistro='Valor'
                                    className='rxlib-select-label-coluna'
                                    referencia={register({ required: false })}
                                    onChange={(e) => setCampoSelecionado(e.currentTarget.value)} />
                            </div>
                            <div className='col-2'>
                                <InputLabel
                                    type='text'
                                    name='valor'
                                    label='Valor:'
                                    maxLength={50}
                                    id='inputValor'
                                    required={false}
                                    readOnly={false}
                                    autoFocus={false}
                                    defaultValue={filtros.valor}
                                    className='rxlib-input-label-coluna'
                                    placeholder='Valor que será filtrado'
                                    referencia={register({ required: false })}
                                    mask={campoSelecionado === 'CnpjBase' ? 'number' : undefined} />
                            </div>
                            <div className='col-2'>
                                <SelectLabelAsync
                                    foco='nao'
                                    action=''
                                    type='Produto'
                                    required='nao'
                                    name='idProduto'
                                    label='Produto:'
                                    id='inputIdProduto'
                                    valorSelecionado={filtros.idProduto}
                                    referencia={register({ required: false })}
                                    className='rxlib-select-label-async-coluna' />
                            </div>
                            <div className='col-1 rxlib-celula-botao'>
                                <Button
                                    type='submit'
                                    texto='Pesquisar'
                                    classStyle='btn-rxlib' />
                            </div>
                            <div className='col-2' />
                            <div className='col-2 rxlib-celula-botao'>
                                {
                                    (temPermissaoDataPadrao)
                                        ? <Button
                                            className=''
                                            type='button'
                                            classStyle='btn-rxlib'
                                            texto='Altera data padrão da licença'
                                            onClick={() => setShowModalAlteraDataPadraoLicenca(true)} />
                                        : ''
                                }
                            </div>
                            <div className='col-1 rxlib-celula-botao'>
                                {
                                    (linkNovo !== '')
                                        ? <ButtonLink
                                            texto='Novo'
                                            link={linkNovo}
                                            classStyle='btn-rxlib' />
                                        : ''
                                }
                            </div>
                        </div>
                        <div className='row px-1 mt-2'>
                            <Listagem
                                campoId='Id'
                                usarOrdenacao={true}
                                carregando={carregando}
                                linkEdicao={linkEdicao}
                                listagemDetalhe={false}
                                usarExportacaoCsv={true}
                                tipoBotaoAcao={'button'}
                                onExcluir={handleExclusao}
                                fonteDados={licencasDesktop}
                                linkListagem='/licencadesktop'
                                csvFilename={'licenças-desktop'}
                                configuracoesTabela={configuracoesTabela}
                                linkVisualizacao='/licencadesktop/visualizar'
                                acoesPersonalizadas={obterAcoesPersonalizadas()} />
                            <ModalHistoricoAcessoLicenca
                                idLicencaDesktop={idLicencaDesktop}
                                show={showModalHistoricoAcessoLicenca}
                                onHide={() => setShowModalHistoricoAcessoLicenca(false)} />
                        </div>
                    </div>
                </form>
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <LicencaDesktopExclusao
                    show={showExclusao}
                    tratarErro={tratarErro}
                    onHide={handleHideExclusao}
                    onExcluir={setRealizarConsulta}
                    idLicencaDesktop={idLicencaDesktop} />
                <DataPadraoExpiracaoLicenca
                    show={showModalAlteraDataPadraoLicenca}
                    onHide={handleHideModalAlteraDataPadraoLicenca} />
            </RxlibLayout>
        </>
    );
}

export default LicencaDesktopListagem;