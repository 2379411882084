import { AxiosError } from 'axios';
import api from '../../../services/api';
import UsuarioExclusao from '../exclusao';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../../rxlib/components/buttons/button';
import InputLabel from '../../../rxlib/components/input-label';
import { ApiError, ListagemProps } from '../../../services/tipos';
import { tratarErroApi } from '../../../rxlib/services/utilitarios';
import { Listagem } from '../../../rxlib/components/layout/listagem';
import ButtonLink from '../../../rxlib/components/buttons/button-link';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';
import { AcoesTabela, ConfiguracoesTabela } from '../../../rxlib/components/table';
import { SelectLabelAsync } from '../../../rxlib/components/select/select-label-async';

interface Filtros {
    nome: string;
    email: string;
    idLicenciada: string;
}

function UsuarioListagem(props: ListagemProps) {
    const [usuarios, setUsuarios] = useState<[{}]>([{}]);
    const [idUsuario, setIdUsuario] = useState<string>('');
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [showExclusao, setShowExclusao] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [realizarConsulta, setRealizarConsulta] = useState<boolean>(false);

    const [filtros, setFiltros] = useState<Filtros>({
        nome: '',
        email: '',
        idLicenciada: '',
    });

    const { register, handleSubmit } = useForm<Filtros>();

    const handleHide = () => setShowWarning(false);

    const handleHideExclusao = () => setShowExclusao(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Usuários', link: '' },
    ];

    const configuracoesTabela: ConfiguracoesTabela = {
        mensagemPadrao: 'Não foram encontrados resultados para a consulta.',
        colunas: [
            { nome: 'Nome', campo: 'Nome', tipo: 'string' },
            { nome: 'E-mail', campo: 'Email', tipo: 'string' },
            { nome: 'Licenciada', entidadePai: 'Licenciada', campo: 'Nome', tipo: 'string' },
            { nome: 'Administrador', campo: 'Administrador', tipo: 'boolean' },
        ],
    }

    let temPermissao = usuarioTemPermissao(token, 'usuario_visualizar');
    let linkNovo = usuarioTemPermissao(token, 'usuario_novo') ? '/usuario/novo' : '';
    let linkEdicao = usuarioTemPermissao(token, 'usuario_editar') ? '/usuario/editar' : '';
    const handleExclusao = usuarioTemPermissao(token, 'usuario_excluir') ? handleExcluir : undefined;

    useEffect(() => {
        if ((temPermissao) && (realizarConsulta)) {
            setCarregando(true);

            let request = '';

            if ((filtros.nome !== '') || (filtros.email !== '') || (filtros.idLicenciada !== '')) {
                request += '&$filter=';
            }

            if (filtros.nome !== '') {
                request += `contains(toupper(nome),toupper('${filtros.nome}'))`;
            }

            if ((filtros.nome !== '') && ((filtros.email !== '') || (filtros.idLicenciada !== ''))) {
                request += ' and ';
            }

            if (filtros.email !== '') {
                request += `contains(toupper(email),toupper('${filtros.email}'))`;
            }

            if ((filtros.email !== '') && (filtros.idLicenciada !== '')) {
                request += ' and ';
            }

            if (filtros.idLicenciada !== '') {
                request += `idLicenciada eq ${filtros.idLicenciada}`;
            }

            if (request !== '') {
                request = '/OData/Usuario?$expand=Licenciada' + request;

                api.get(request)
                    .then(response => {
                        setTimeout(() => {
                            setUsuarios(response.data.value);
                            setRealizarConsulta(false);
                            setCarregando(false);
                        }, 250);
                    }).catch((error: AxiosError<ApiError>) => {
                        setCarregando(false);
                        setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                        setShowWarning(true);
                    });
            } else {
                setCarregando(false);
                setMessageWarning(['Não foi informado um filtro para realizar a busca']);
                setShowWarning(true);
            }
        }
    }, [temPermissao, filtros, realizarConsulta]);

    function obterAcoesPersonalizadas(): AcoesTabela[] {
        const acoesPersonalizadas: AcoesTabela[] = [];

        if (usuarioTemPermissao(token, 'produto_usuario_visualizar')) {
            acoesPersonalizadas.push({ link: '/usuario/produto', nome: 'Produtos', iconeClassName: 'fas fa-ticket-alt me-3' });
        }

        if (usuarioTemPermissao(token, 'documento_acesso_usuario_visualizar')) {
            acoesPersonalizadas.push({ link: '/usuario/documentoacesso', nome: 'Documentos para acesso', iconeClassName: 'fas fas fa-id-card me-3' });
        }

        return acoesPersonalizadas;
    }

    const onSubmit: SubmitHandler<Filtros> = data => {
        setFiltros({
            nome: data.nome,
            email: data.email,
            idLicenciada: data.idLicenciada,
        });
        setRealizarConsulta(true);
    }

    function handleExcluir(id: string) {
        setIdUsuario(id);
        setShowExclusao(true);
    }

    function tratarErro(error: AxiosError<ApiError>, mensagem: string) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, mensagem));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='container-fluid'>
                        <div className='row px-1'>
                            <div className='col-12'>
                                <h6>Listagem de usuários</h6>
                            </div>
                        </div>
                        <div className='row px-1 mt-2'>
                            <div className='col-3'>
                                <InputLabel
                                    name='nome'
                                    type='text'
                                    label='Nome:'
                                    id='inputNome'
                                    maxLength={50}
                                    readOnly={false}
                                    autoFocus={true}
                                    required={false}
                                    defaultValue={filtros.nome}
                                    placeholder='Nome do usuário'
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: false })} />
                            </div>
                            <div className='col-3'>
                                <InputLabel
                                    type='text'
                                    name='email'
                                    label='E-mail:'
                                    id='inputEmail'
                                    maxLength={100}
                                    readOnly={false}
                                    required={false}
                                    autoFocus={false}
                                    defaultValue={filtros.email}
                                    placeholder='E-mail do usuário'
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: false })} />
                            </div>
                            <div className='col-3'>
                                <SelectLabelAsync
                                    action=''
                                    foco='nao'
                                    required='nao'
                                    type='Licenciada'
                                    name='idLicenciada'
                                    label='Licenciada:'
                                    id='inputIdLicenciada'
                                    valorSelecionado={filtros.idLicenciada}
                                    referencia={register({ required: false })}
                                    className='rxlib-select-label-async-coluna' />
                            </div>
                            <div className='col-1 rxlib-celula-botao'>
                                <Button
                                    type='submit'
                                    texto='Pesquisar'
                                    classStyle='btn-rxlib' />
                            </div>
                            <div className='col-2 ps-3 rxlib-celula-botao'>
                                {
                                    ((linkNovo) && (linkNovo !== ''))
                                        ? <ButtonLink
                                            texto='Novo'
                                            link={linkNovo}
                                            classStyle='btn-rxlib' />
                                        : ''
                                }
                            </div>
                        </div>
                        <div className='row px-1 mt-2'>
                            <Listagem
                                campoId='Id'
                                fonteDados={usuarios}
                                tipoBotaoAcao='button'
                                listagemDetalhe={false}
                                carregando={carregando}
                                linkListagem='/usuario'
                                linkEdicao={linkEdicao}
                                onExcluir={handleExclusao}
                                linkVisualizacao='/usuario/visualizar'
                                configuracoesTabela={configuracoesTabela}
                                acoesPersonalizadas={obterAcoesPersonalizadas()} />
                        </div>
                    </div>
                </form>
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <UsuarioExclusao
                    show={showExclusao}
                    idUsuario={idUsuario}
                    tratarErro={tratarErro}
                    onHide={handleHideExclusao}
                    onExcluir={setRealizarConsulta} />
            </RxlibLayout>
        </>
    );
}

export default UsuarioListagem;