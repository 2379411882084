import { AxiosError } from "axios";
import { Modal } from "react-bootstrap";
import api from "../../../services/api";
import { useState, useEffect } from "react";
import Grid from "../../../components/grid";
import { ApiError } from "../../../services/tipos";
import Button from "../../../rxlib/components/buttons/button";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid/models";
import { formatarTamanhoDoBanco } from "../../../services/utilitarios";
import { ModalWarning } from "../../../rxlib/components/modal/modal-warning";
import { tratarErroApi, formatarDataHora, } from "../../../rxlib/services/utilitarios";

interface ModalHistoricoAcessoLicencaProps {
    show: boolean;
    idLicencaDesktop: string;
    onHide: () => void;
}

export function ModalHistoricoAcessoLicenca(props: ModalHistoricoAcessoLicencaProps) {
    const [dados, setDados] = useState<GridRowsProp>([]);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [licencaDesktopNome, setLicencaDesktopNome] = useState<string>("");

    const colunas: GridColDef[] = [
        { field: "versao", headerName: "Versão", width: 100, headerClassName: "caronte-grid-header", cellClassName: "caronte-grid-cell" },
        { field: "codigo", headerName: "Código", width: 120, headerClassName: "caronte-grid-header", cellClassName: "caronte-grid-cell" },
        { field: "nome", headerName: "Nome", width: 200, headerClassName: "caronte-grid-header", cellClassName: "caronte-grid-cell" },
        { field: "ultimoAcesso", headerName: "Último Acesso", width: 180, headerClassName: "caronte-grid-header", cellClassName: "caronte-grid-cell" },
        { field: "tamanhoBanco", headerName: "Tamanho do Banco", width: 200, headerClassName: "caronte-grid-header", cellClassName: "caronte-grid-cell" },
        { field: "sistemaOperacional", headerName: "Sistema Operacional", width: 300, headerClassName: "caronte-grid-header", cellClassName: "caronte-grid-cell" },
    ];

    useEffect(() => {
        if (props.idLicencaDesktop !== "") {
            setCarregando(true);

            api.get(`/LicencaDesktop/${props.idLicencaDesktop}`)
                .then(response => {
                    setLicencaDesktopNome(response.data.nome);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, "Não foi possível realizar a consulta: "));
                    setShowWarning(true);
                });


            Promise.all([
                api.get(`/HistoricoAcesssoUsuario/LicencaDesktop/${props.idLicencaDesktop}`),
                api.get(`/HistoricoAcessoLicenca/LicencaDesktop/${props.idLicencaDesktop}`),
            ]).then(response => {
                let linhas: any[] = [];
                for (let i = 0; i < response[0].data.length; i++) {
                    linhas.push({
                        id: response[0].data[i].id,
                        versao: response[1].data.versao,
                        codigo: response[0].data[i].codigo,
                        nome: response[0].data[i].nome,
                        ultimoAcesso: formatarDataHora(response[0].data[i].ultimoAcesso),
                        sistemaOperacional: response[0].data[i].sistemaOperacional,
                        tamanhoBanco: formatarTamanhoDoBanco(response[0].data[i].tamanhoBanco),
                    });
                }

                setDados(linhas);
                setCarregando(false);
            }).catch((error: AxiosError<ApiError>) => {
                setDados([]);
                setCarregando(false);
                if (error.response?.status !== 404) {
                    setMessageWarning(tratarErroApi(error, "Não foi possível realizar a consulta: "));
                    setShowWarning(true);
                }
            });
        }
    }, [props.idLicencaDesktop, props.show]);

    return (
        <Modal enforceFocus={false} show={props.show} onHide={props.onHide} size="lg" centered>
            <Modal.Header>
                <Modal.Title>
                    Histórico de acesso a licença: <br />
                    {licencaDesktopNome}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (dados.length > 0)
                        ? <div style={{ height: 350 }}>
                            <Grid
                                linhas={dados}
                                colunas={colunas}
                                carregando={carregando} />
                        </div>
                        : <div style={{ height: 25 }}>
                            Não houve registro de acesso a esta licença desktop.
                        </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    texto="Fecha"
                    classStyle="btn-rxlib"
                    onClick={props.onHide} />
            </Modal.Footer>
            <ModalWarning
                show={showWarning}
                message={messageWarning}
                onHide={() => setShowWarning(false)} />
        </Modal>
    );
}