import { AxiosError } from 'axios';
import api from '../../../services/api';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { useForm, SubmitHandler } from 'react-hook-form';
import InputLabel from '../../../rxlib/components/input-label';
import Redirecionar from '../../../rxlib/components/redirecionar';
import { tratarErroApi } from '../../../rxlib/services/utilitarios';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import ButtonsCrud from '../../../rxlib/components/buttons/buttons-crud';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import { ModalPrimary } from '../../../rxlib/components/modal/modal-primary';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';
import { ApiError, CadastroProps, VersaoAtualProduto } from '../../../services/tipos';
import { SelectLabelAsync } from '../../../rxlib/components/select/select-label-async';

function VersaoAtualProdutoCadastro(props: CadastroProps) {
    const [salvo, setSalvo] = useState<boolean>(false);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showPrimary, setShowPrimary] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [messagePrimary, setMessagePrimary] = useState<string[]>([]);

    const [versaoAtualProduto, setVersaoAtualProduto] = useState<VersaoAtualProduto>({
        id: '',
        build: 0,
        release: 0,
        idProduto: '',
        versaoMenor: 0,
        versaoPrincipal: 0,
    });

    const { register, handleSubmit } = useForm<VersaoAtualProduto>();

    const handleHideWarning = () => setShowWarning(false);

    const handleHidePrimary = () => {
        setShowPrimary(false);
        setSalvo(true);
    }

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Versão atual dos produtos', link: '/versaoatualproduto' },
        { texto: props.match.params.action ? 'Visualização' : props.match.params.id ? 'Edição' : 'Novo', link: '' },
    ];

    const temPermissao = props.match.params.action
        ? usuarioTemPermissao(token, 'versao_atual_produto_visualizar')
        : props.match.params.id
            ? usuarioTemPermissao(token, 'versao_atual_produto_editar')
            : usuarioTemPermissao(token, 'versao_atual_produto_novo');

    useEffect(() => {
        if (temPermissao) {
            if (props.match.params.id) {
                api.get(`/VersaoAtualProduto/${props.match.params.id}`)
                    .then(response => {
                        setVersaoAtualProduto({
                            id: response.data.id,
                            build: response.data.build,
                            release: response.data.release,
                            idProduto: response.data.idProduto,
                            versaoMenor: response.data.versaoMenor,
                            versaoPrincipal: response.data.versaoPrincipal,
                        });
                    }).catch((error: AxiosError<ApiError>) => {
                        tratarErro(error);
                    });
            }
        }
    }, [props.match.params.id, temPermissao]);

    const onSubmit: SubmitHandler<VersaoAtualProduto> = data => {
        setCarregando(true);
        props.match.params.id
            ? editar(data)
            : salvar(data);
    }

    function salvar(data: VersaoAtualProduto) {
        api.post('/VersaoAtualProduto', data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function editar(data: VersaoAtualProduto) {
        api.put('/VersaoAtualProduto', data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function informarSucesso() {
        setMessagePrimary(['Versão atual do produto salva com sucesso.']);
        setShowPrimary(true);
    }

    function tratarErro(error: AxiosError<ApiError>) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, 'Não foi possível salvar a versão atual do produto: '));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <form onSubmit={handleSubmit(onSubmit)} className='rxlib-form'>
                    <div className='container-fluid'>
                        <div className='row px-1'>
                            <div className='col-12'>
                                <h6>{props.match.params.action ? 'Visualizar' : props.match.params.id ? 'Editar' : 'Nova'} versão atual do produto</h6>
                            </div>
                        </div>
                        {
                            props.match.params.id
                                ? <div className='row px-1'>
                                    <div className='col-12 mt-1'>
                                        <InputLabel
                                            name='id'
                                            type='text'
                                            id='inputId'
                                            maxLength={50}
                                            readOnly={true}
                                            label='Código:'
                                            autoFocus={false}
                                            defaultValue={versaoAtualProduto.id}
                                            referencia={register({ required: true })}
                                            placeholder='Código da versão atual do produto' />
                                    </div>
                                </div>
                                : ''
                        }
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <SelectLabelAsync
                                    foco='sim'
                                    type='Produto'
                                    name='idProduto'
                                    label='Produto:'
                                    id='inputIdProduto'
                                    action={props.match.params.action}
                                    referencia={register({ required: true })}
                                    valorSelecionado={versaoAtualProduto.idProduto} />
                            </div>
                        </div>
                        <div className='row px-1 mt-1'>
                            <div className='col-1'>
                                <InputLabel
                                    type='text'
                                    mask='number'
                                    maxLength={10}
                                    autoFocus={false}
                                    name='versaoPrincipal'
                                    label='Versão principal:'
                                    id='inputVersaoPrincipal'
                                    placeholder='Versão principal'
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'}
                                    defaultValue={String(versaoAtualProduto.versaoPrincipal)} />
                            </div>
                            <div className='col-1'>
                                <InputLabel
                                    type='text'
                                    mask='number'
                                    maxLength={10}
                                    autoFocus={false}
                                    name='versaoMenor'
                                    label='Versão menor:'
                                    id='inputVersaoMenor'
                                    placeholder='Versão menor'
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'}
                                    defaultValue={String(versaoAtualProduto.versaoMenor)} />
                            </div>
                            <div className='col-1'>
                                <InputLabel
                                    type='text'
                                    name='build'
                                    mask='number'
                                    label='Build:'
                                    maxLength={10}
                                    id='inputBuild'
                                    autoFocus={false}
                                    placeholder='Build'
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'}
                                    defaultValue={String(versaoAtualProduto.build)} />
                            </div>
                            <div className='col-1'>
                                <InputLabel
                                    type='text'
                                    mask='number'
                                    name='release'
                                    maxLength={10}
                                    label='Release:'
                                    autoFocus={false}
                                    id='inputRelease'
                                    placeholder='Release'
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'}
                                    defaultValue={String(versaoAtualProduto.release)} />
                            </div>
                            <div className='col-8'></div>
                        </div>
                        <ButtonsCrud
                            carregando={carregando}
                            styleButton='btn-rxlib'
                            linkCancelarVoltar='/versaoatualproduto'
                            visualizar={props.match.params.action === 'view'} />
                    </div>
                </form>
                <ModalWarning
                    show={showWarning}
                    message={messageWarning}
                    onHide={handleHideWarning} />
                <ModalPrimary
                    show={showPrimary}
                    message={messagePrimary}
                    onHide={handleHidePrimary} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <Redirecionar
                    se={salvo}
                    para='/versaoatualproduto' />
            </RxlibLayout>
        </>
    );
}

export default VersaoAtualProdutoCadastro;