import { AxiosError } from 'axios';
import api from '../../services/api';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../store/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../rxlib/components/buttons/button';
import InputLabel from '../../rxlib/components/input-label';
import { ApiError, ListagemProps } from '../../services/tipos';
import { tratarErroApi } from '../../rxlib/services/utilitarios';
import { Listagem } from '../../rxlib/components/layout/listagem';
import { ConfiguracoesTabela } from '../../rxlib/components/table';
import { usuarioTemPermissao } from '../../rxlib/services/seguranca';
import AcessoBloqueado from '../../rxlib/components/acesso-bloqueado';
import { RxlibLayout } from '../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../rxlib/components/modal/modal-warning';
import Breadcrumb, { BreadcrumbItem } from '../../rxlib/components/breadcrumb';
import { SelectLabelAsync } from '../../rxlib/components/select/select-label-async';

interface Filtros {
    nome: string;
    idLicenciada: string;
}

function AcessoUsuario(props: ListagemProps) {
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [acessosUsuario, setAcessosUsuario] = useState<[{}]>([{}]);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [realizarConsulta, setRealizarConsulta] = useState<boolean>(false);

    const [filtros, setFiltros] = useState<Filtros>({
        nome: '',
        idLicenciada: '',
    });

    const { register, handleSubmit } = useForm<Filtros>();

    const handleHide = () => setShowWarning(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Acessos dos usuários', link: '' },
    ];

    const configuracoesTabela: ConfiguracoesTabela = {
        mensagemPadrao: 'Não foram encontrados resultados para a consulta.',
        colunas: [
            { nome: 'Nome', entidadePai: 'Usuario', campo: 'Nome', tipo: 'string' },
            { nome: 'E-mail', entidadePai: 'Usuario', campo: 'Email', tipo: 'string' },
            { nome: 'Licenciada', entidadeAvo: 'Usuario', entidadePai: 'Licenciada', campo: 'Nome', tipo: 'string' },
            { nome: 'Produto', entidadePai: 'Produto', campo: 'Nome', tipo: 'string' },
            { nome: 'Último acesso', campo: 'UltimoAcesso', tipo: 'datetime' },
            { nome: 'Sincronizado', campo: 'Sincronizado', tipo: 'boolean' },
        ],
    }

    let temPermissao = usuarioTemPermissao(token, 'acesso_usuario_visualizar');

    useEffect(() => {
        if ((temPermissao) && (realizarConsulta)) {
            setCarregando(true);

            let request = '';

            if ((filtros.nome !== '') || (filtros.idLicenciada !== '')) {
                request += '&$filter=';
            }

            if (filtros.nome !== '') {
                request += `contains(toupper(Usuario/Nome),toupper('${filtros.nome}'))`;
            }

            if ((filtros.nome !== '') && (filtros.idLicenciada !== '')) {
                request += ' and ';
            }

            if (filtros.idLicenciada !== '') {
                request += `Usuario/Licenciada/Id eq ${filtros.idLicenciada}`;
            }

            if (request !== '') {
                request = '/OData/AcessoUsuario?$expand=Produto,Usuario($expand=Licenciada)' + request;

                api.get(request)
                    .then(response => {
                        setTimeout(() => {
                            setAcessosUsuario(response.data.value);
                            setRealizarConsulta(false);
                            setCarregando(false);
                        }, 250);
                    }).catch((error: AxiosError<ApiError>) => {
                        setCarregando(false);
                        setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                        setShowWarning(true);
                    });
            } else {
                setCarregando(false);
                setMessageWarning(['Não foi informado um filtro para realizar a busca']);
                setShowWarning(true);
            }
        }
    }, [temPermissao, filtros, realizarConsulta]);

    const onSubmit: SubmitHandler<Filtros> = data => {
        setFiltros({
            nome: data.nome,
            idLicenciada: data.idLicenciada,
        });
        setRealizarConsulta(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='container-fluid'>
                        <div className='row px-1'>
                            <div className='col-12'>
                                <h6>Listagem de acessos dos usuários</h6>
                            </div>
                        </div>
                        <div className='row px-1 mt-2'>
                            <div className='col-2'>
                                <InputLabel
                                    name='nome'
                                    type='text'
                                    label='Nome:'
                                    id='inputNome'
                                    maxLength={50}
                                    readOnly={false}
                                    autoFocus={true}
                                    required={false}
                                    defaultValue={filtros.nome}
                                    placeholder='Nome do usuário'
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: false })} />
                            </div>
                            <div className='col-2'>
                                <SelectLabelAsync
                                    action=''
                                    foco='nao'
                                    required='nao'
                                    type='Licenciada'
                                    name='idLicenciada'
                                    label='Licenciada:'
                                    id='inputIdLicenciada'
                                    valorSelecionado={filtros.idLicenciada}
                                    referencia={register({ required: false })}
                                    className='rxlib-select-label-async-coluna' />
                            </div>
                            <div className='col-1 rxlib-celula-botao'>
                                <Button
                                    type='submit'
                                    texto='Pesquisar'
                                    classStyle='btn-rxlib' />
                            </div>
                            <div className='col-7' />
                        </div>
                        <div className='row px-1 mt-2'>
                            <Listagem
                                campoId='Id'
                                tipoBotaoAcao='button'
                                listagemDetalhe={false}
                                carregando={carregando}
                                fonteDados={acessosUsuario}
                                configuracoesTabela={configuracoesTabela} />
                        </div>
                    </div>
                </form>
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
                <AcessoBloqueado
                    permissao={temPermissao} />
            </RxlibLayout>
        </>
    );
}

export default AcessoUsuario;