import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import {
    ptBR,
    DataGrid,
    GridColDef,
    GridToolbar,
    GridRowsProp,
} from "@mui/x-data-grid";

import "./grid.css";

interface GridProps {
    carregando: boolean;
    linhas: GridRowsProp,
    colunas: GridColDef[],
}

const StyledGridOverlay = styled("div")(() => ({
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <Box sx={{ mt: 1 }}>Não foram encontrados resultados para a consulta.</Box>
        </StyledGridOverlay>
    );
}

function Grid(props: GridProps) {
    return (
        <DataGrid
            rowHeight={40}
            rows={props.linhas}
            rowSelection={false}
            columns={props.colunas}
            columnHeaderHeight={40}
            loading={props.carregando}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slots={{
                toolbar: GridToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
            }}
            getRowClassName={(params) =>
                (params.indexRelativeToCurrentPage % 2 === 0)
                    ? "caronte-grid-row-striped-none"
                    : "caronte-grid-row-striped"
            } />
    );
}

export default Grid;