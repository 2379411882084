import { AxiosError } from 'axios';
import api from '../../../services/api';
import PerfilExclusao from '../exclusao';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { ApiError, ListagemProps } from '../../../services/tipos';
import { tratarErroApi } from '../../../rxlib/services/utilitarios';
import { Listagem } from '../../../rxlib/components/layout/listagem';
import { ConfiguracoesTabela } from '../../../rxlib/components/table';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';

function PerfilListagem(props: ListagemProps) {
    const [perfis, setPerfis] = useState<[{}]>([{}]);
    const [idPerfil, setIdPerfil] = useState<string>('');
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [showExclusao, setShowExclusao] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [realizarConsulta, setRealizarConsulta] = useState<boolean>(true);

    const handleHide = () => setShowWarning(false);

    const handleHideExclusao = () => setShowExclusao(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Perfis de usuários', link: '' },
    ];

    const configuracoesTabela: ConfiguracoesTabela = {
        mensagemPadrao: 'Não foram encontrados resultados para a consulta.',
        colunas: [
            { nome: 'Nome', campo: 'Nome', tipo: 'string' },
        ],
    }

    const temPermissao = usuarioTemPermissao(token, 'perfil_usuario_visualizar');
    const linkNovo = usuarioTemPermissao(token, 'perfil_usuario_novo') ? '/perfil/novo' : '';
    const linkEdicao = usuarioTemPermissao(token, 'perfil_usuario_editar') ? '/perfil/editar' : '';
    const handleExclusao = usuarioTemPermissao(token, 'perfil_usuario_excluir') ? handleExcluir : undefined;

    useEffect(() => {
        if ((temPermissao) && (realizarConsulta)) {
            setCarregando(true);
            api.get('/OData/Perfil')
                .then(response => {
                    setTimeout(() => {
                        setPerfis(response.data.value);
                        setRealizarConsulta(false);
                        setCarregando(false);
                    }, 250);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                    setShowWarning(true);
                });
        }
    }, [temPermissao, realizarConsulta]);

    function handleExcluir(id: string) {
        setIdPerfil(id);
        setShowExclusao(true);
    }

    function tratarErro(error: AxiosError<ApiError>, mensagem: string) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, mensagem));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <Listagem
                    campoId='Id'
                    fonteDados={perfis}
                    linkNovo={linkNovo}
                    linkListagem='/perfil'
                    tipoBotaoAcao='button'
                    listagemDetalhe={false}
                    carregando={carregando}
                    linkEdicao={linkEdicao}
                    onExcluir={handleExclusao}
                    linkVisualizacao='/perfil/visualizar'
                    configuracoesTabela={configuracoesTabela}
                    descricao='Listagem de perfis de usuários' />
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <PerfilExclusao
                    show={showExclusao}
                    idPerfil={idPerfil}
                    tratarErro={tratarErro}
                    onHide={handleHideExclusao}
                    onExcluir={setRealizarConsulta} />
            </RxlibLayout>
        </>
    );
}

export default PerfilListagem;