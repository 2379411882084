/* RXLib Config */

import { usuarioTemPermissao } from '../rxlib/services/seguranca';
import { TipoColunaTabelaBase } from '../rxlib/components/table';
import { Item, Menu } from '../rxlib/components/layout/rxlib-Layout';
import { RequestType } from '../rxlib/components/select/select-label-async';

/* SelectLabelAsync */

export const SelectLabelAsyncPrimaryColor = '#6F7376';
export const SelectLabelAsyncDisabledColor = '#CCCCCC';
export const SelectLabelAsyncSecondaryColor = '#FFFFFF';

export type DataType = 'Produto' | 'DadosFixos' | 'Licenciada';

export const RequestTypeArray: RequestType[] = [
    {
        useOdata: true,
        type: 'Produto',
        fieldValue: 'Id',
        fieldLabel: 'Nome',
        fieldValueLowerCase: 'id',
        fieldLabelLowerCase: 'nome',
        url: '/OData/Produto?$filter=contains(toupper(nome),toupper(\'selectedValue\'))',
    },
    {
        useOdata: true,
        fieldValue: 'Id',
        fieldLabel: 'Nome',
        type: 'Licenciada',
        fieldValueLowerCase: 'id',
        fieldLabelLowerCase: 'nome',
        url: '/OData/Licenciada?$filter=contains(toupper(nome),toupper(\'selectedValue\'))',
    },
];

/* RxlibLayout */

export const NomeEmpresa = 'Caronte';
export const UsarSidebarLayout = false;
export const SecretKey = 'f366b841282d4d17';
export const UsarSidebarBodyGradient = false;
export const ExibirNomeEmpresaNavbarLayout = false;

function usuarioPodeVerCadastro(token: string): boolean {
    return ((usuarioTemPermissao(token, 'usuario_visualizar')) ||
        (usuarioTemPermissao(token, 'perfil_usuario_visualizar')) ||
        (usuarioTemPermissao(token, 'licenciada_visualizar')) ||
        (usuarioTemPermissao(token, 'produto_visualizar')) ||
        (usuarioTemPermissao(token, 'versao_atual_produto_visualizar')) ||
        (usuarioTemPermissao(token, 'licenca_desktop_visualizar')));
}

function usuarioPodeVerMovimentacao(token: string): boolean {
    return ((usuarioTemPermissao(token, 'controle_acesso_visualizar')) ||
        (usuarioTemPermissao(token, 'acesso_usuario_visualizar')));
}

export function montarMenus(token: string, administrador: boolean): Menu {
    let menus: Menu = {
        items: [],
    };

    if (usuarioPodeVerCadastro(token)) {
        let item: Item = {
            nome: 'Cadastros',
            subItens: [],
        }

        if (usuarioTemPermissao(token, 'usuario_visualizar'))
            item.subItens.push({
                nome: 'Usuários',
                link: '/usuario',
            });

        if (usuarioTemPermissao(token, 'perfil_usuario_visualizar'))
            item.subItens.push({
                nome: 'Perfis de usuários',
                link: '/perfil',
            });

        if (usuarioTemPermissao(token, 'licenciada_visualizar'))
            item.subItens.push({
                nome: 'Licenciadas',
                link: '/licenciada',
            });

        if (usuarioTemPermissao(token, 'produto_visualizar'))
            item.subItens.push({
                nome: 'Produtos',
                link: '/produto',
            });

        if (usuarioTemPermissao(token, 'versao_atual_produto_visualizar'))
            item.subItens.push({
                nome: 'Versão atual dos produtos',
                link: '/versaoatualproduto',
            });

        if (usuarioTemPermissao(token, 'licenca_desktop_visualizar'))
            item.subItens.push({
                nome: 'Licenças desktops',
                link: '/licencadesktop',
            });

        menus.items.push(item);
    }

    if (usuarioPodeVerMovimentacao(token)) {
        let item: Item = {
            nome: 'Movimentações',
            subItens: [],
        }

        if (usuarioTemPermissao(token, 'controle_acesso_visualizar'))
            item.subItens.push({
                nome: 'Controle de acesso',
                link: '/controleacesso',
            });

        if (usuarioTemPermissao(token, 'acesso_usuario_visualizar'))
            item.subItens.push({
                nome: 'Acessos dos usuários',
                link: '/acessousuario',
            });

        menus.items.push(item);
    }

    return menus;
}

/* Table  */

export type TipoColunaTabela = TipoColunaTabelaBase;