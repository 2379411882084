import { AxiosError } from 'axios';
import api from '../../../services/api';
import { useState, useEffect } from 'react';
import VersaoAtualProdutoExclusao from '../exclusao';
import { useAppSelector } from '../../../store/hooks';
import { ApiError, ListagemProps } from '../../../services/tipos';
import { tratarErroApi } from '../../../rxlib/services/utilitarios';
import { Listagem } from '../../../rxlib/components/layout/listagem';
import { ConfiguracoesTabela } from '../../../rxlib/components/table';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';

function VersaoAtualProdutoListagem(props: ListagemProps) {
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [showExclusao, setShowExclusao] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [realizarConsulta, setRealizarConsulta] = useState<boolean>(true);
    const [versaoAtualProdutos, setVersaoAtualProdutos] = useState<[{}]>([{}]);
    const [idVersaoAtualProduto, setIdVersaoAtualProduto] = useState<string>('');

    const handleHide = () => setShowWarning(false);

    const handleHideExclusao = () => setShowExclusao(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Versão atual dos produtos', link: '' },
    ];

    const configuracoesTabela: ConfiguracoesTabela = {
        mensagemPadrao: 'Não foram encontrados resultados para a consulta.',
        colunas: [
            { nome: 'Produto', campo: 'Nome', entidadePai: 'Produto', tipo: 'string' },
            { nome: 'Versão principal', campo: 'VersaoPrincipal', tipo: 'string' },
            { nome: 'Versão menor', campo: 'VersaoMenor', tipo: 'string' },
            { nome: 'Build', campo: 'Build', tipo: 'string' },
            { nome: 'Release', campo: 'Release', tipo: 'string' },
        ],
    }

    const temPermissao = usuarioTemPermissao(token, 'versao_atual_produto_visualizar');
    const linkNovo = usuarioTemPermissao(token, 'versao_atual_produto_novo') ? '/versaoatualproduto/novo' : '';
    const linkEdicao = usuarioTemPermissao(token, 'versao_atual_produto_editar') ? '/versaoatualproduto/editar' : '';
    const handleExclusao = usuarioTemPermissao(token, 'versao_atual_produto_excluir') ? handleExcluir : undefined;

    useEffect(() => {
        if ((temPermissao) && (realizarConsulta)) {
            setCarregando(true);
            api.get(`/OData/VersaoAtualProduto?$expand=Produto`)
                .then(response => {
                    setTimeout(() => {
                        setVersaoAtualProdutos(response.data.value);
                        setRealizarConsulta(false);
                        setCarregando(false);
                    }, 250);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                    setShowWarning(true);
                });
        }
    }, [temPermissao, realizarConsulta]);

    function handleExcluir(id: string) {
        setIdVersaoAtualProduto(id);
        setShowExclusao(true);
    }

    function tratarErro(error: AxiosError<ApiError>, mensagem: string) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, mensagem));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <Listagem
                    campoId='Id'
                    linkNovo={linkNovo}
                    carregando={carregando}
                    linkEdicao={linkEdicao}
                    listagemDetalhe={false}
                    tipoBotaoAcao={'button'}
                    onExcluir={handleExclusao}
                    fonteDados={versaoAtualProdutos}
                    linkListagem='/versaoAtualProduto'
                    configuracoesTabela={configuracoesTabela}
                    descricao='Listagem de versões dos produtos'
                    linkVisualizacao='/versaoatualproduto/visualizar' />
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <VersaoAtualProdutoExclusao
                    show={showExclusao}
                    tratarErro={tratarErro}
                    onHide={handleHideExclusao}
                    onExcluir={setRealizarConsulta}
                    idVersaoAtualProduto={idVersaoAtualProduto} />
            </RxlibLayout>
        </>
    );
}

export default VersaoAtualProdutoListagem;