import { AxiosError } from 'axios';
import api from '../../../services/api';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { ApiError, ListagemProps } from '../../../services/tipos';
import { tratarErroApi } from '../../../rxlib/services/utilitarios';
import { Listagem } from '../../../rxlib/components/layout/listagem';
import { ConfiguracoesTabela } from '../../../rxlib/components/table';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';

function ProdutoListagem(props: ListagemProps) {
    const [produtos, setProdutos] = useState<[{}]>([{}]);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [realizarConsulta, setRealizarConsulta] = useState<boolean>(true);

    const handleHide = () => setShowWarning(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Produtos', link: '' },
    ];

    const configuracoesTabela: ConfiguracoesTabela = {
        mensagemPadrao: 'Não foram encontrados resultados para a consulta.',
        colunas: [
            { nome: 'Código', campo: 'Id', tipo: 'string' },
            { nome: 'Nome', campo: 'Nome', tipo: 'string' },
        ],
    }

    const linkNovo = '';
    const handleExclusao = undefined;
    const temPermissao = usuarioTemPermissao(token, 'produto_visualizar');
    const linkEdicao = usuarioTemPermissao(token, 'produto_editar') ? '/produto/editar' : '';

    useEffect(() => {
        if ((temPermissao) && (realizarConsulta)) {
            setCarregando(true);
            api.get('/OData/Produto')
                .then(response => {
                    setTimeout(() => {
                        setProdutos(response.data.value);
                        setRealizarConsulta(false);
                        setCarregando(false);
                    }, 250);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                    setShowWarning(true);
                });
        }
    }, [temPermissao, realizarConsulta]);

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <Listagem
                    campoId='Id'
                    linkNovo={linkNovo}
                    fonteDados={produtos}
                    carregando={carregando}
                    linkEdicao={linkEdicao}
                    linkListagem='/produto'
                    listagemDetalhe={false}
                    tipoBotaoAcao={'button'}
                    onExcluir={handleExclusao}
                    descricao='Listagem de produtos'
                    linkVisualizacao='/produto/visualizar'
                    configuracoesTabela={configuracoesTabela} />
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
                <AcessoBloqueado
                    permissao={temPermissao} />
            </RxlibLayout>
        </>
    );
}

export default ProdutoListagem;