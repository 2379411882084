import { AxiosError } from 'axios';
import api from '../../../services/api';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { useForm, SubmitHandler } from 'react-hook-form';
import Checkbox from '../../../rxlib/components/checkbox';
import InputLabel from '../../../rxlib/components/input-label';
import Redirecionar from '../../../rxlib/components/redirecionar';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import ButtonsCrud from '../../../rxlib/components/buttons/buttons-crud';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import { ModalPrimary } from '../../../rxlib/components/modal/modal-primary';
import { ApiError, CadastroProps, LicencaDesktop } from '../../../services/tipos';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';
import { SelectLabelAsync } from '../../../rxlib/components/select/select-label-async';
import { formatarDataParaComponente, tratarErroApi } from '../../../rxlib/services/utilitarios';

function LicencaDesktopCadastro(props: CadastroProps) {
    const [salvo, setSalvo] = useState<boolean>(false);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showPrimary, setShowPrimary] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [messagePrimary, setMessagePrimary] = useState<string[]>([]);
    const [token] = useState<string>(useAppSelector(state => state.token));
    const [clienteProvenienteVenda, setClienteProvenienteVenda] = useState<boolean>(false);
    const [utilizaDataExpiracaoPersonalizada, setUtilizaDataExpiracaoPersonalizada] = useState<boolean>(false);

    const [licencaDesktop, setLicencaDesktop] = useState<LicencaDesktop>({
        id: '',
        nome: '',
        build: 0,
        release: 0,
        cnpjBase: '',
        idProduto: '',
        expiracao: '',
        versaoMenor: 0,
        versaoPrincipal: 0,
        clienteProvenienteVenda: false,
        utilizaDataExpiracaoPersonalizada: false,
    });

    const { register, handleSubmit, setValue } = useForm<LicencaDesktop>();

    const handleHideWarning = () => setShowWarning(false);

    const handleHidePrimary = () => {
        setShowPrimary(false);
        setSalvo(true);
    }

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Licenças desktop', link: '/licencadesktop' },
        { texto: props.match.params.action ? 'Visualização' : props.match.params.id ? 'Edição' : 'Novo', link: '' },
    ];

    const temPermissao: boolean = props.match.params.action
        ? usuarioTemPermissao(token, 'licenca_desktop_visualizar')
        : props.match.params.id
            ? usuarioTemPermissao(token, 'licenca_desktop_editar')
            : usuarioTemPermissao(token, 'licenca_desktop_novo');

    useEffect(() => {
        if (temPermissao) {
            if (props.match.params.id) {
                api.get(`/LicencaDesktop/${props.match.params.id}`)
                    .then(response => {
                        setLicencaDesktop({
                            id: response.data.id,
                            nome: response.data.nome,
                            build: response.data.build,
                            release: response.data.release,
                            cnpjBase: response.data.cnpjBase,
                            idProduto: response.data.idProduto,
                            expiracao: response.data.expiracao,
                            versaoMenor: response.data.versaoMenor,
                            versaoPrincipal: response.data.versaoPrincipal,
                            clienteProvenienteVenda: response.data.clienteProvenienteVenda,
                            utilizaDataExpiracaoPersonalizada: response.data.utilizaDataExpiracaoPersonalizada,
                        });

                        setClienteProvenienteVenda(response.data.clienteProvenienteVenda);
                        setUtilizaDataExpiracaoPersonalizada(response.data.utilizaDataExpiracaoPersonalizada);
                    }).catch((error: AxiosError<ApiError>) => {
                        tratarErro(error);
                    });
            }
        }
    }, [props.match.params.id, temPermissao]);

    useEffect(() => {
        const atualizarDataExpiracaoParaDataPadraoLicenca = () => {
            api.get(`/DataPadraoExpiracaoLicenca`)
                .then(response => {
                    setValue('expiracao', formatarDataParaComponente(response.data[0].data))
                }).catch(((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                    setShowWarning(true);
                }));
        }

        if (!utilizaDataExpiracaoPersonalizada && !props.match.params.id) {
            atualizarDataExpiracaoParaDataPadraoLicenca();
        }
    }, [utilizaDataExpiracaoPersonalizada, props.match.params.id, setValue]);

    const onSubmit: SubmitHandler<LicencaDesktop> = data => {
        if (data.cnpjBase.length !== 8) {
            setMessageWarning(['O campo CNPJ deve conter 8 caracteres.']);
            setShowWarning(true);
        } else {
            setCarregando(true);
            props.match.params.id
                ? editar(data)
                : salvar(data);
        }
    }

    function definirDados(data: LicencaDesktop) {
        data.build = !(data.build) ? 0 : data.build;
        data.release = !(data.release) ? 0 : data.release;
        data.versaoMenor = !(data.versaoMenor) ? 0 : data.versaoMenor;
        data.clienteProvenienteVenda = Boolean(data.clienteProvenienteVenda);
        data.versaoPrincipal = !(data.versaoPrincipal) ? 0 : data.versaoPrincipal;
        data.utilizaDataExpiracaoPersonalizada = Boolean(data.utilizaDataExpiracaoPersonalizada);
    }

    function salvar(data: LicencaDesktop) {
        definirDados(data);
        api.post('/LicencaDesktop', data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function editar(data: LicencaDesktop) {
        definirDados(data);
        api.put('/LicencaDesktop', data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function onClickUtilizaDataExpiracaoPersonalizada(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        const isDataPesonlizada: boolean = (e.target as HTMLInputElement).checked
        setUtilizaDataExpiracaoPersonalizada(isDataPesonlizada);
        if (!isDataPesonlizada) {
            atualizarDataExpiracaoParaDataPadrao();
        }
    }

    const atualizarDataExpiracaoParaDataPadrao = () => {
        api.get(`/DataPadraoExpiracaoLicenca`)
            .then(response => {
                setValue('expiracao', formatarDataParaComponente(response.data[0].data))
            }).catch(((error: AxiosError<ApiError>) => {
                setCarregando(false);
                setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                setShowWarning(true);
            }));
    }

    function informarSucesso() {
        setMessagePrimary(['Licença desktop salva com sucesso.']);
        setShowPrimary(true);
    }

    function tratarErro(error: AxiosError<ApiError>) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, 'Não foi possível salvar a licença desktop: '));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <form onSubmit={handleSubmit(onSubmit)} className='rxlib-form'>
                    <div className='container-fluid'>
                        <div className='row px-1'>
                            <div className='col-12'>
                                <h6>{props.match.params.action ? 'Visualizar' : props.match.params.id ? 'Editar' : 'Nova'} licença desktop</h6>
                            </div>
                        </div>
                        {
                            props.match.params.id
                                ? <div className='row px-1'>
                                    <div className='col-12 mt-1'>
                                        <InputLabel
                                            name='id'
                                            type='text'
                                            id='inputId'
                                            maxLength={50}
                                            readOnly={true}
                                            label='Código:'
                                            autoFocus={false}
                                            placeholder='Código da licença'
                                            defaultValue={licencaDesktop.id}
                                            referencia={register({ required: true })} />
                                    </div>
                                </div>
                                : ''
                        }
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    name='nome'
                                    type='text'
                                    label='Nome:'
                                    id='inputNome'
                                    maxLength={50}
                                    autoFocus={true}
                                    placeholder='Nome da licença'
                                    defaultValue={licencaDesktop.nome}
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    type='text'
                                    label='CNPJ:'
                                    id='inputCnpj'
                                    maxLength={10}
                                    name='cnpjBase'
                                    autoFocus={false}
                                    placeholder='CNPJ da licença'
                                    defaultValue={licencaDesktop.cnpjBase}
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-2'>
                                <Checkbox
                                    text="Data de expiração personalizada"
                                    name="utilizaDataExpiracaoPersonalizada"
                                    referencia={register({ required: false })}
                                    disabled={props.match.params.action === 'view'}
                                    value={String(licencaDesktop.utilizaDataExpiracaoPersonalizada)}
                                    defaultChecked={licencaDesktop.utilizaDataExpiracaoPersonalizada}
                                    onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => onClickUtilizaDataExpiracaoPersonalizada(e)} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    label=''
                                    type='date'
                                    maxLength={10}
                                    name='expiracao'
                                    autoFocus={false}
                                    id='inputExpiracao'
                                    placeholder='  /  /    '
                                    referencia={register({ required: true })}
                                    defaultValue={formatarDataParaComponente(licencaDesktop.expiracao)}
                                    readOnly={(props.match.params.action === 'view') || (!utilizaDataExpiracaoPersonalizada)} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-2'>
                                <Checkbox
                                    text="Proveniente de Venda"
                                    name="clienteProvenienteVenda"
                                    referencia={register({ required: false })}
                                    disabled={props.match.params.action === 'view'}
                                    value={String(licencaDesktop.clienteProvenienteVenda)}
                                    defaultChecked={licencaDesktop.clienteProvenienteVenda}
                                    onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => setClienteProvenienteVenda((e.target as HTMLInputElement).checked)} />
                            </div>
                        </div>
                        <div className='row px-1 mt-1'>
                            <div className='col-1'>
                                <InputLabel
                                    type='text'
                                    mask='number'
                                    maxLength={10}
                                    required={false}
                                    autoFocus={false}
                                    name='versaoPrincipal'
                                    label='Versão principal:'
                                    id='inputVersaoPrincipal'
                                    placeholder='Versão principal'
                                    limpar={!clienteProvenienteVenda}
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: false })}
                                    defaultValue={String(licencaDesktop.versaoPrincipal)}
                                    readOnly={props.match.params.action === 'view' || (!clienteProvenienteVenda)} />
                            </div>
                            <div className='col-1'>
                                <InputLabel
                                    type='text'
                                    mask='number'
                                    maxLength={10}
                                    required={false}
                                    autoFocus={false}
                                    name='versaoMenor'
                                    label='Versão menor:'
                                    id='inputVersaoMenor'
                                    placeholder='Versão menor'
                                    limpar={!clienteProvenienteVenda}
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: false })}
                                    defaultValue={String(licencaDesktop.versaoMenor)}
                                    readOnly={props.match.params.action === 'view' || (!clienteProvenienteVenda)} />
                            </div>
                            <div className='col-1'>
                                <InputLabel
                                    type='text'
                                    name='build'
                                    mask='number'
                                    label='Build:'
                                    maxLength={10}
                                    id='inputBuild'
                                    required={false}
                                    autoFocus={false}
                                    placeholder='Build'
                                    limpar={!clienteProvenienteVenda}
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: false })}
                                    defaultValue={String(licencaDesktop.build)}
                                    readOnly={props.match.params.action === 'view' || (!clienteProvenienteVenda)} />
                            </div>
                            <div className='col-1'>
                                <InputLabel
                                    type='text'
                                    mask='number'
                                    name='release'
                                    maxLength={10}
                                    label='Release:'
                                    required={false}
                                    autoFocus={false}
                                    id='inputRelease'
                                    placeholder='Release'
                                    limpar={!clienteProvenienteVenda}
                                    className='rxlib-input-label-coluna'
                                    referencia={register({ required: false })}
                                    defaultValue={String(licencaDesktop.release)}
                                    readOnly={props.match.params.action === 'view' || (!clienteProvenienteVenda)} />
                            </div>
                            <div className='col-8'></div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <SelectLabelAsync
                                    foco='nao'
                                    type='Produto'
                                    name='idProduto'
                                    label='Produto:'
                                    id='inputIdProduto'
                                    action={props.match.params.action}
                                    referencia={register({ required: true })}
                                    valorSelecionado={licencaDesktop.idProduto} />
                            </div>
                        </div>
                        <ButtonsCrud
                            carregando={carregando}
                            styleButton='btn-rxlib'
                            linkCancelarVoltar='/licencadesktop'
                            visualizar={props.match.params.action === 'view'} />
                    </div>
                </form>
                <ModalWarning
                    show={showWarning}
                    message={messageWarning}
                    onHide={handleHideWarning} />
                <ModalPrimary
                    show={showPrimary}
                    message={messagePrimary}
                    onHide={handleHidePrimary} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <Redirecionar
                    se={salvo}
                    para='/licencadesktop' />
            </RxlibLayout>
        </>
    );
}

export default LicencaDesktopCadastro;