import { AxiosError } from 'axios';
import api from '../../../services/api';
import { useState, useEffect } from 'react';
import LicenciadaExclusao from '../exclusao';
import { useAppSelector } from '../../../store/hooks';
import { ApiError, ListagemProps } from '../../../services/tipos';
import { tratarErroApi } from '../../../rxlib/services/utilitarios';
import { Listagem } from '../../../rxlib/components/layout/listagem';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';
import { AcoesTabela, ConfiguracoesTabela } from '../../../rxlib/components/table';

function LicenciadaListagem(props: ListagemProps) {
    const [licenciadas, setLicenciadas] = useState<[{}]>([{}]);
    const [idLicenciada, setIdLicenciada] = useState<string>('');
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [showExclusao, setShowExclusao] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [realizarConsulta, setRealizarConsulta] = useState<boolean>(true);

    const handleHide = () => setShowWarning(false);

    const handleHideExclusao = () => setShowExclusao(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Licenciadas', link: '' },
    ];

    const configuracoesTabela: ConfiguracoesTabela = {
        mensagemPadrao: 'Não foram encontrados resultados para a consulta.',
        colunas: [
            { nome: 'Código', campo: 'Id', tipo: 'string' },
            { nome: 'Nome', campo: 'Nome', tipo: 'string' },
            { nome: 'CNPJ', campo: 'Cnpj', tipo: 'string' },
        ],
    }

    const temPermissao = usuarioTemPermissao(token, 'licenciada_visualizar');
    const linkNovo = usuarioTemPermissao(token, 'licenciada_novo') ? '/licenciada/novo' : '';
    const linkEdicao = usuarioTemPermissao(token, 'licenciada_editar') ? '/licenciada/editar' : '';
    const handleExclusao = usuarioTemPermissao(token, 'licenciada_excluir') ? handleExcluir : undefined;

    useEffect(() => {
        if ((temPermissao) && (realizarConsulta)) {
            setCarregando(true);
            api.get('/OData/Licenciada')
                .then(response => {
                    setTimeout(() => {
                        setLicenciadas(response.data.value);
                        setRealizarConsulta(false);
                        setCarregando(false);
                    }, 250);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                    setShowWarning(true);
                });
        }
    }, [temPermissao, realizarConsulta]);

    function obterAcoesPersonalizadas(): AcoesTabela[] {
        const acoesPersonalizadas: AcoesTabela[] = [];

        if (usuarioTemPermissao(token, 'produto_licenciada_visualizar')) {
            acoesPersonalizadas.push({ link: '/licenciada/produtolicenciada', nome: 'Produtos da licenciada', iconeClassName: 'fas fas fa-box me-3' });
        }

        return acoesPersonalizadas;
    }

    function handleExcluir(id: string) {
        setIdLicenciada(id);
        setShowExclusao(true);
    }

    function tratarErro(error: AxiosError<ApiError>, mensagem: string) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, mensagem));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <Listagem
                    campoId='Id'
                    linkNovo={linkNovo}
                    carregando={carregando}
                    linkEdicao={linkEdicao}
                    listagemDetalhe={false}
                    tipoBotaoAcao={'button'}
                    fonteDados={licenciadas}
                    linkListagem='/licenciada'
                    onExcluir={handleExclusao}
                    descricao='Listagem de licenciadas'
                    linkVisualizacao='/licenciada/visualizar'
                    configuracoesTabela={configuracoesTabela}
                    acoesPersonalizadas={obterAcoesPersonalizadas()} />
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <LicenciadaExclusao
                    show={showExclusao}
                    tratarErro={tratarErro}
                    idLicenciada={idLicenciada}
                    onHide={handleHideExclusao}
                    onExcluir={setRealizarConsulta} />
            </RxlibLayout>
        </>
    );
}

export default LicenciadaListagem;