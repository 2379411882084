/* rxlib - Exclusao v2.0.0 */

import Button from '../../buttons/button';
import ButtonLink from '../../buttons/button-link';

interface ExclusaoProps {
    titulo: string;
    voltar?: boolean;
    carregando: boolean;
    onSubmit: () => void;
    linkCancelar?: string;
    onCancelar?: () => void;
}

export function Exclusao(props: ExclusaoProps) {

    let habilitarVoltar: boolean = false;
    if (props.voltar) {
        habilitarVoltar = props.voltar;
    }

    return (
        <form onSubmit={props.onSubmit}>
            <div className='container-fluid'>
                <div className='row px-1'>
                    <div className='col-12 text-center'>
                        <h6>{props.titulo}</h6>
                    </div>
                </div>
                <div className='row px-1 mt-1'>
                    <div className='col-12 d-flex text-center'>
                        {
                            (habilitarVoltar)
                                ? ''
                                : <div className={
                                    props.carregando
                                        ? 'rxlib-exclusao-button-com-spinner rxlib-exclusao-com-spinner '
                                        : 'rxlib-exclusao-button rxlib-exclusao '
                                }>

                                    <Button
                                        type='submit'
                                        texto='Excluir'
                                        classStyle='btn-rxlib'
                                        carregando={props.carregando} />
                                </div>
                        }
                        {
                            !props.carregando
                                ? <div className={'rxlib-cancelar-button ' + (habilitarVoltar ? 'rxlib-voltar' : 'rxlib-cancelar')}>
                                    {
                                        ((props.linkCancelar) && (props.linkCancelar !== ''))
                                            ? <ButtonLink
                                                classStyle='btn-rxlib'
                                                link={props.linkCancelar}
                                                texto={habilitarVoltar ? 'Voltar' : 'Cancelar'} />
                                            : (!!props.onCancelar)
                                                ? <Button
                                                    type='button'
                                                    classStyle='btn-rxlib'
                                                    onClick={props.onCancelar}
                                                    texto={habilitarVoltar ? 'Voltar' : 'Cancelar'} />
                                                : ''
                                    }
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </div>
        </form>
    );
}