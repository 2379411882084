import { AxiosError } from 'axios';
import api from '../../services/api';
import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../store/hooks';
import Spinner from '../../rxlib/components/spinner';
import { Popover } from '../../rxlib/components/popover';
import { tratarErroApi } from '../../rxlib/services/utilitarios';
import AcessoBloqueado from '../../rxlib/components/acesso-bloqueado';
import { TreePermissoes } from '../../rxlib/components/tree-permissoes';
import { RxlibLayout } from '../../rxlib/components/layout/rxlib-Layout';
import { SelectLabel } from '../../rxlib/components/select/select-label';
import { ModalWarning } from '../../rxlib/components/modal/modal-warning';
import Breadcrumb, { BreadcrumbItem } from '../../rxlib/components/breadcrumb';
import { usuarioTemPermissao, obterIdUsuario } from '../../rxlib/services/seguranca';
import { Permissoes, PerfilUsuario, PerfilPermissao, ApiError } from '../../services/tipos';

function ControleAcesso() {
    const [idPerfil, setIdPerfil] = useState<string>('');
    const [carregando, setCarregando] = useState<boolean>(false);
    const [permissoes, setPermissoes] = useState<Permissoes[]>([]);
    const [token] = useState(useAppSelector(state => state.token));
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [perfis, setPerfis] = useState<[{ [key: string]: string; }]>([{}]);
    const [perfilsUsuario, setPerfilsUsuario] = useState<PerfilUsuario[]>([]);
    const [perfilPermissao, setPerfilPermissao] = useState<PerfilPermissao[]>([]);

    const handleHideWarning = () => setShowWarning(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Controle de acesso', link: '' },
    ];

    const idUsuarioLogado = obterIdUsuario(token);
    const temPermissao = usuarioTemPermissao(token, 'controle_acesso_visualizar');
    const naoPodeEditar = !usuarioTemPermissao(token, 'controle_acesso_editar');

    useEffect(() => {
        setCarregando(true);
        Promise.all([
            api.get('/Perfil'),
            api.get(`/PerfilUsuario/Usuario/${idUsuarioLogado}`)
        ]).then(response => {
            setTimeout(() => {
                setPerfis(response[0].data);
                setIdPerfil(response[0].data[0].id);
                setPerfilsUsuario(response[1].data);
                setCarregando(false);
            }, 250);
        }).catch((error: AxiosError<ApiError>) => {
            tratarErro(error);
            setCarregando(false);
        });
    }, [idUsuarioLogado]);

    useEffect(() => {
        if (idPerfil !== '') {
            setCarregando(true);
            Promise.all([
                api.get('/Permissao'),
                api.get(`/PerfilPermissao/Perfil/${idPerfil}`)
            ]).then(response => {
                setTimeout(() => {
                    setPermissoes([]);
                    setPerfilPermissao([]);
                    setPermissoes(response[0].data);
                    setPerfilPermissao(response[1].data);
                    setCarregando(false);
                }, 250);
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
                setCarregando(false);
            });
        }
    }, [idPerfil]);

    function salvar(idPermissao: string) {
        perfilPermissao.find(item => item.idPermissao === idPermissao)
            ? remover(idPermissao)
            : criar(idPermissao);
    }

    function criar(idPermissao: string) {
        const data: PerfilPermissao = {
            idPerfil: idPerfil,
            idPermissao: idPermissao,
        }

        api.post('/PerfilPermissao', data)
            .then(response => {
                perfilPermissao.push(data);
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function remover(idPermissao: string) {
        api.delete(`/PerfilPermissao/Perfil/${idPerfil}/Permissao/${idPermissao}`)
            .then(response => {
                let permissao = perfilPermissao.find(item => item.idPermissao === idPermissao);
                if (permissao) {
                    perfilPermissao
                        .splice(perfilPermissao
                            .indexOf(permissao), 1);
                }
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function tratarErro(error: AxiosError<ApiError>) {
        setMessageWarning(tratarErroApi(error, 'Não foi possível alterar o acesso do perfil: '));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <div className='container-fluid mb-5'>
                    <div className='row px-1'>
                        <div className='col-12'>
                            <h6>Controle de acesso</h6>
                        </div>
                    </div>
                    {
                        !carregando
                            ? <>
                                <div className='row px-1'>
                                    <div className='col-12 mt-1 d-flex'>
                                        <div className='rxlib-select-perfil d-grid me-2'>
                                            <SelectLabel
                                                action=''
                                                foco='sim'
                                                itens={perfis}
                                                name='idPerfil'
                                                label='Perfil:'
                                                id='inputPerfil'
                                                ariaLabel='Perfil'
                                                valorSelecionado={idPerfil}
                                                campoExibicaoRegistro='nome'
                                                referencia={React.createRef()}
                                                campoIdentificadorRegistro='id'
                                                className='rxlib-select-label-coluna'
                                                onChange={(e) => setIdPerfil(e.currentTarget.value)} />
                                        </div>
                                        <div className='align-self-end'>
                                            <Popover
                                                text='Caso o usuário logado possua o perfil selecionado o mesmo não poderá mudar suas permissões.' />
                                        </div>
                                    </div>
                                </div>
                                <div className='row px-1'>
                                    <div className='col-12 mt-2'>
                                        <label className='form-label'>Permissões:</label>
                                        <TreePermissoes
                                            permissoes={permissoes}
                                            idPerfilSelecionao={idPerfil}
                                            naoPodeEditar={naoPodeEditar}
                                            perfilsUsuario={perfilsUsuario}
                                            perfilPermissao={perfilPermissao}
                                            onChange={(e) => salvar(e.currentTarget.value)} />
                                    </div>
                                </div>
                            </>
                            : <Spinner
                                classStyle='rxlib-spinner' />
                    }
                </div>
                <ModalWarning
                    show={showWarning}
                    message={messageWarning}
                    onHide={handleHideWarning} />
                <AcessoBloqueado
                    permissao={temPermissao} />
            </RxlibLayout>
        </>
    );
}

export default ControleAcesso;