import Home from './view/home';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './view/login';
import PageNotFound from './view/page-not-found';
import AcessoUsuario from './view/acesso-usuario';
import ControleAcesso from './view/controle-acesso';

import PerfilListagem from './view/perfil/listagem';
import PerfilCadastro from './view/perfil/cadastro';
import PerfilExclusao from './view/perfil/exclusao';

import UsuarioListagem from './view/usuario/listagem';
import UsuarioCadastro from './view/usuario/cadastro';
import UsuarioExclusao from './view/usuario/exclusao';

import LicenciadaListagem from './view/licenciada/listagem';
import LicenciadaCadastro from './view/licenciada/cadastro';
import LicenciadaExclusao from './view/licenciada/exclusao';

import ProdutoListagem from './view/produto/listagem';
import ProdutoCadastro from './view/produto/cadastro';

import ProdutoUsuarioListagem from './view/produto-usuario/listagem';
import ProdutoUsuarioCadastro from './view/produto-usuario/cadastro';
import ProdutoUsuarioExclusao from './view/produto-usuario/exclusao';

import DocumentoAcessoListagem from './view/documento-acesso/listagem';
import DocumentoAcessoCadastro from './view/documento-acesso/cadastro';
import DocumentoAcessoExclusao from './view/documento-acesso/exclusao';

import ProdutoLicenciadaListagem from './view/produto-licenciada/listagem';
import ProdutoLicenciadaCadastro from './view/produto-licenciada/cadastro';
import ProdutoLicenciadaExclusao from './view/produto-licenciada/exclusao';

import VersaoAtualProdutoListagem from './view/versao-atual-produto/listagem';
import VersaoAtualProdutoCadastro from './view/versao-atual-produto/cadastro';
import VersaoAtualProdutoExclusao from './view/versao-atual-produto/exclusao';

import LicencaDesktopListagem from './view/licenca-desktop/listagem';
import LicencaDesktopCadastro from './view/licenca-desktop/cadastro';
import LicencaDesktopExclusao from './view/licenca-desktop/exclusao';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/login' component={Login} />
            <Route path='/login/:acao' component={Login} />
            <Route exact path='/home' component={Home} />
            <Route path='/home/:acao' component={Home} />

            <Route exact path='/usuario' component={UsuarioListagem} />
            <Route path='/usuario/pagina/:pagina' component={UsuarioListagem} />
            <Route exact path='/usuario/novo' component={UsuarioCadastro} />
            <Route path='/usuario/editar/:id' component={UsuarioCadastro} />
            <Route path='/usuario/visualizar/:id/:action' component={UsuarioCadastro} />
            <Route path='/usuario/exclusao/:id' component={UsuarioExclusao} />

            <Route exact path='/usuario/produto/:idUsuario' component={ProdutoUsuarioListagem} />
            <Route path='/usuario/produto/:idUsuario/pagina/:pagina' component={ProdutoUsuarioListagem} />
            <Route exact path='/usuario/produto/:idUsuario/novo' component={ProdutoUsuarioCadastro} />
            <Route path='/usuario/produto/:idUsuario/editar/:id' component={ProdutoUsuarioCadastro} />
            <Route path='/usuario/produto/:idUsuario/visualizar/:id/:action' component={ProdutoUsuarioCadastro} />
            <Route path='/usuario/produto/:idUsuario/exclusao/:id' component={ProdutoUsuarioExclusao} />

            <Route exact path='/usuario/documentoacesso/:idUsuario' component={DocumentoAcessoListagem} />
            <Route path='/usuario/documentoacesso/:idUsuario/pagina/:pagina' component={DocumentoAcessoListagem} />
            <Route exact path='/usuario/documentoacesso/:idUsuario/novo' component={DocumentoAcessoCadastro} />
            <Route path='/usuario/documentoacesso/:idUsuario/editar/:id' component={DocumentoAcessoCadastro} />
            <Route path='/usuario/documentoacesso/:idUsuario/visualizar/:id/:action' component={DocumentoAcessoCadastro} />
            <Route path='/usuario/documentoacesso/:idUsuario/exclusao/:id' component={DocumentoAcessoExclusao} />

            <Route exact path='/perfil' component={PerfilListagem} />
            <Route path='/perfil/pagina/:pagina' component={PerfilListagem} />
            <Route exact path='/perfil/novo' component={PerfilCadastro} />
            <Route path='/perfil/editar/:id' component={PerfilCadastro} />
            <Route path='/perfil/visualizar/:id/:action' component={PerfilCadastro} />
            <Route path='/perfil/exclusao/:id' component={PerfilExclusao} />

            <Route exact path='/licenciada' component={LicenciadaListagem} />
            <Route path='/licenciada/pagina/:pagina' component={LicenciadaListagem} />
            <Route exact path='/licenciada/novo' component={LicenciadaCadastro} />
            <Route path='/licenciada/editar/:id' component={LicenciadaCadastro} />
            <Route path='/licenciada/visualizar/:id/:action' component={LicenciadaCadastro} />
            <Route path='/licenciada/exclusao/:id' component={LicenciadaExclusao} />

            <Route exact path='/produto' component={ProdutoListagem} />
            <Route path='/produto/pagina/:pagina' component={ProdutoListagem} />
            <Route exact path='/produto/novo' component={ProdutoCadastro} />
            <Route path='/produto/editar/:id' component={ProdutoCadastro} />
            <Route path='/produto/visualizar/:id/:action' component={ProdutoCadastro} />

            <Route exact path='/versaoatualproduto' component={VersaoAtualProdutoListagem} />
            <Route path='/versaoatualproduto/pagina/:pagina' component={VersaoAtualProdutoListagem} />
            <Route exact path='/versaoatualproduto/novo' component={VersaoAtualProdutoCadastro} />
            <Route path='/versaoatualproduto/editar/:id' component={VersaoAtualProdutoCadastro} />
            <Route path='/versaoatualproduto/visualizar/:id/:action' component={VersaoAtualProdutoCadastro} />
            <Route path='/versaoatualproduto/exclusao/:id' component={VersaoAtualProdutoExclusao} />

            <Route exact path='/licenciada/produtolicenciada/:idLicenciada' component={ProdutoLicenciadaListagem} />
            <Route path='/licenciada/produtolicenciada/:idLicenciada/pagina/:pagina' component={ProdutoLicenciadaListagem} />
            <Route exact path='/licenciada/produtolicenciada/:idLicenciada/novo' component={ProdutoLicenciadaCadastro} />
            <Route path='/licenciada/produtolicenciada/:idLicenciada/editar/:id' component={ProdutoLicenciadaCadastro} />
            <Route path='/licenciada/produtolicenciada/:idLicenciada/visualizar/:id/:action' component={ProdutoLicenciadaCadastro} />
            <Route path='/licenciada/produtolicenciada/:idLicenciada/exclusao/:id' component={ProdutoLicenciadaExclusao} />

            <Route exact path='/licencadesktop' component={LicencaDesktopListagem} />
            <Route path='/licencadesktop/pagina/:pagina' component={LicencaDesktopListagem} />
            <Route exact path='/licencadesktop/novo' component={LicencaDesktopCadastro} />
            <Route path='/licencadesktop/editar/:id' component={LicencaDesktopCadastro} />
            <Route path='/licencadesktop/visualizar/:id/:action' component={LicencaDesktopCadastro} />
            <Route path='/licencadesktop/exclusao/:id' component={LicencaDesktopExclusao} />

            <Route exact path='/controleacesso' component={ControleAcesso} />
            <Route exact path='/acessousuario' component={AcessoUsuario} />

            <Route path='*' component={PageNotFound} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;