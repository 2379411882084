import './home.css';
import { AxiosError } from 'axios';
import api from '../../services/api';
import { useEffect, useState } from 'react';
import { Card } from '../../rxlib/components/card';
import Spinner from '../../rxlib/components/spinner';
import { AcessoProps, ApiError } from '../../services/tipos';
import { RxlibLayout } from '../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../rxlib/components/modal/modal-warning';
import { formatarData, tratarErroApi } from '../../rxlib/services/utilitarios';

function Home(props: AcessoProps) {
    const [acao, setAcao] = useState(props.match.params.acao);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [quantidadeUsuarios, setQuantidadeUsuarios] = useState<string>('');
    const [quantidadeProdutos, setQuantidadeProdutos] = useState<string>('');
    const [quantidadeLicenciadas, setQuantidadeLicenciadas] = useState<string>('');
    const [quantidadeLicencasDesktop, setQuantidadeLicencasDesktop] = useState<string>('');
    const [dataPadraoExpiracaoLicenca, setDataPadraoExpiracaoLicenca] = useState<string>('');

    const handleHide = () => setShowWarning(false);

    function showMensagemSemAcesso() {
        setMessageWarning(['Usuário logado não tem permissão para realizar essa ação.']);
        setShowWarning(true);
        setAcao('');
    }

    useEffect(() => {
        setCarregando(true);
        setTimeout(() => {
            Promise.all([
                api.get('/OData/Usuario?$count=true'),
                api.get(`/OData/Produto?$count=true`),
                api.get('/DataPadraoExpiracaoLicenca'),
                api.get('/OData/Licenciada?$count=true'),
                api.get('/OData/LicencaDesktop?completa=true&$count=true'),
            ]).then(response => {
                setQuantidadeUsuarios(response[0].data['@odata.count']);
                setQuantidadeProdutos(response[1].data['@odata.count']);
                setDataPadraoExpiracaoLicenca(response[2].data[0].data);
                setQuantidadeLicenciadas(response[3].data['@odata.count']);
                setQuantidadeLicencasDesktop(response[4].data['@odata.count']);
                setCarregando(false);
            }).catch((error: AxiosError<ApiError>) => {
                setCarregando(false);
                setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                setShowWarning(true);
            });
        }, 250);
    }, []);

    return (
        <>
            {
                acao === 'acessobloqueado'
                    ? showMensagemSemAcesso()
                    : ''
            }
            <RxlibLayout>
                {
                    carregando
                        ? <Spinner
                            classStyle='rxlib-spinner'
                            classNameSpinner='rxlib-home-spinner' />
                        : <div className='rxlib-home-menu'>
                            <div className='rxlib-home-cards'>
                                <div>
                                    <Card
                                        subtitulo=''
                                        texto={quantidadeLicenciadas}
                                        classNameIcone='fas fa-user-tie'
                                        titulo='Quantidade de licenciadas web' />
                                </div>
                                <div>
                                    <Card
                                        subtitulo=''
                                        classNameIcone='fas fa-desktop'
                                        texto={quantidadeLicencasDesktop}
                                        titulo='Quantidade de licenças desktop' />
                                </div>
                            </div>
                            <div className='rxlib-home-cards mt-3'>
                                <div>
                                    <Card
                                        subtitulo=''
                                        texto={quantidadeUsuarios}
                                        classNameIcone='fas fa-user'
                                        titulo='Quantidade de usuários web' />
                                </div>
                                <div className='rxlib-home-data-padrao-expiracao-licenca'>
                                    <Card
                                        subtitulo=''
                                        classNameIcone='fas fa-calendar'
                                        titulo='Data padrão expiração licença'
                                        texto={formatarData(dataPadraoExpiracaoLicenca)} />
                                </div>
                            </div>
                            <div className='rxlib-home-cards mt-3'>
                                <div>
                                    <Card
                                        subtitulo=''
                                        texto={quantidadeProdutos}
                                        classNameIcone='fas fa-box'
                                        titulo='Quantidade de produtos' />
                                </div>
                            </div>
                        </div>
                }
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
            </RxlibLayout>
        </>
    );
}

export default Home;