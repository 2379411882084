import { AxiosError } from 'axios';
import { Modal } from 'react-bootstrap';
import api from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { ApiError } from '../../../services/tipos';
import { useAppSelector } from '../../../store/hooks';
import Spinner from '../../../rxlib/components/spinner';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../../rxlib/components/buttons/button';
import InputLabel from '../../../rxlib/components/input-label';
import { tratarErroApi } from '../../../rxlib/services/utilitarios';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import { ModalPrimary } from '../../../rxlib/components/modal/modal-primary';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import { formatarDataParaComponente } from '../../../rxlib/services/utilitarios';

interface DataPadraoExpiracaoLicencaProps {
    show: boolean;
    onHide: () => void;
}

interface DataPadraoExpiracao {
    id: string;
    data: string;
}

function DataPadraoExpiracaoLicenca(props: DataPadraoExpiracaoLicencaProps) {
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showPrimary, setShowPrimary] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [messagePrimary, setMessagePrimary] = useState<string[]>([]);

    const [dataPadrao, setDataPadrao] = useState<DataPadraoExpiracao>({
        id: '',
        data: '',
    });

    const [token] = useState(useAppSelector(state => state.token));

    const { register, handleSubmit } = useForm<DataPadraoExpiracao>();

    const handleHideWarning = () => setShowWarning(false);

    const handleHidePrimary = () => setShowPrimary(false);

    const temPermissaoDeApenasVisualizar =
        ((usuarioTemPermissao(token, 'licenca_desktop_visualizar')) &&
            (!usuarioTemPermissao(token, 'licenca_desktop_novo')) &&
            (!usuarioTemPermissao(token, 'licenca_desktop_editar')));

    const onSubmit: SubmitHandler<DataPadraoExpiracao> = data => {
        setCarregando(true);
        api.put('/DataPadraoExpiracaoLicenca', data)
            .then(response => {
                props.onHide();
                setCarregando(false);
                setMessagePrimary(['Data salva com sucesso!'])
                setShowPrimary(true);
            }).catch((error: AxiosError<ApiError>) => {
                setCarregando(false)
                setMessageWarning(tratarErroApi(error, 'Não foi possível alterar a data:'))
                setShowWarning(true);
            });
    }

    useEffect(() => {
        setCarregando(true);
        if (usuarioTemPermissao(token, 'licenca_desktop_visualizar') && props.show) {
            api.get(`/DataPadraoExpiracaoLicenca`)
                .then(response => {
                    setDataPadrao(response.data[0]);
                    setCarregando(false);
                }).catch(((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                    setShowWarning(true);
                }))
        }
    }, [token, props.show]);

    return (
        <>
            <Modal ref={React.createRef()} show={props.show} onHide={props.onHide} centered>
                <Modal.Body>
                    <h6 className='pb-2 ps-3'>Alterar data padrão de expiração das licenças desktop</h6>
                    {
                        carregando
                            ? <Spinner
                                classStyle='rxlib-spinner' />
                            : <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='container-fluid'>
                                    <div className='row px-1 mt-1 d-none'>
                                        <div className='col-12 mt-1'>
                                            <InputLabel
                                                name='id'
                                                type='text'
                                                id='inputId'
                                                maxLength={50}
                                                readOnly={true}
                                                label='Código:'
                                                autoFocus={false}
                                                defaultValue={dataPadrao.id}
                                                placeholder='Código da data padrão'
                                                referencia={register({ required: true })} />
                                        </div>
                                    </div>
                                    <div className='row px-1 mt-1'>
                                        <div className='col-12'>
                                            <InputLabel
                                                label=''
                                                type='date'
                                                name='data'
                                                id='inputData'
                                                maxLength={10}
                                                autoFocus={true}
                                                placeholder='  /  /    '
                                                className='rxlib-input-label-coluna'
                                                referencia={register({ required: true })}
                                                readOnly={temPermissaoDeApenasVisualizar}
                                                defaultValue={formatarDataParaComponente(dataPadrao.data)} />
                                        </div>
                                    </div>
                                    <div className='row px-1 mt-3'>
                                        {
                                            (!temPermissaoDeApenasVisualizar)
                                                ? <>
                                                    <div className='col-6'>
                                                        <Button
                                                            type='submit'
                                                            texto='Salvar'
                                                            classStyle='btn-rxlib' />
                                                    </div>
                                                    <div className='col-6'>
                                                        <Button
                                                            texto='Sair'
                                                            type='submit'
                                                            onClick={props.onHide}
                                                            classStyle='btn-rxlib' />
                                                    </div>
                                                </>
                                                : <div className='col-12'>
                                                    <Button
                                                        texto='Sair'
                                                        type='submit'
                                                        onClick={props.onHide}
                                                        classStyle='btn-rxlib' />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </form>
                    }
                </Modal.Body>
            </Modal>
            <ModalWarning
                show={showWarning}
                message={messageWarning}
                onHide={handleHideWarning} />
            <ModalPrimary
                show={showPrimary}
                message={messagePrimary}
                onHide={handleHidePrimary} />
        </>
    );
}

export default DataPadraoExpiracaoLicenca;