import { AxiosError } from 'axios';
import api from '../../../services/api';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { useForm, SubmitHandler } from 'react-hook-form';
import Checkbox from '../../../rxlib/components/checkbox';
import InputLabel from '../../../rxlib/components/input-label';
import Redirecionar from '../../../rxlib/components/redirecionar';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import ButtonsCrud from '../../../rxlib/components/buttons/buttons-crud';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalPrimary } from '../../../rxlib/components/modal/modal-primary';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';
import { SelectLabelAsync } from '../../../rxlib/components/select/select-label-async';
import { ApiError, CadastroProps, Perfil, PerfilUsuario, Usuario } from '../../../services/tipos';
import { criptografar, descriptografar, tratarErroApi } from '../../../rxlib/services/utilitarios';

function UsuarioCadastro(props: CadastroProps) {
    const [salvo, setSalvo] = useState<boolean>(false);
    const [perfis, setPerfis] = useState<Perfil[]>([]);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showPrimary, setShowPrimary] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [messagePrimary, setMessagePrimary] = useState<string[]>([]);
    const [perfisUsuario, setPerfisUsuario] = useState<PerfilUsuario[]>([]);

    const [usuario, setUsuario] = useState<Usuario>({
        id: '',
        nome: '',
        email: '',
        senha: '',
        perfis: [''],
        idLicenciada: '',
        administrador: false,
    });

    const { register, handleSubmit } = useForm<Usuario>();

    const handleHideWarning = () => setShowWarning(false);

    const handleHidePrimary = () => {
        setShowPrimary(false);
        setSalvo(true);
    }

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Usuários', link: '/usuario' },
        { texto: props.match.params.action ? 'Visualização' : props.match.params.id ? 'Edição' : 'Novo', link: '' },
    ];

    const temPermissao = props.match.params.action
        ? usuarioTemPermissao(token, 'usuario_visualizar')
        : props.match.params.id
            ? usuarioTemPermissao(token, 'usuario_editar')
            : usuarioTemPermissao(token, 'usuario_novo');

    useEffect(() => {
        if (temPermissao) {
            if (props.match.params.id) {
                Promise.all([
                    api.get(`/Usuario/${props.match.params.id}`),
                    api.get(`/PerfilUsuario/Usuario/${props.match.params.id}`)
                ]).then(response => {
                    setUsuario({
                        id: response[0].data.id,
                        nome: response[0].data.nome,
                        email: response[0].data.email,
                        perfis: response[0].data.perfis,
                        idLicenciada: response[0].data.idLicenciada,
                        administrador: response[0].data.administrador,
                        senha: descriptografar(response[0].data.senha),
                    });
                    setPerfisUsuario(response[1].data);
                }).catch((error: AxiosError<ApiError>) => {
                    tratarErro(error);
                });
            }

            api.get('/Perfil')
                .then(response => {
                    setPerfis(response.data);
                }).catch((error: AxiosError<ApiError>) => {
                    tratarErro(error);
                })
        }
    }, [props.match.params.id, temPermissao]);

    const onSubmit: SubmitHandler<Usuario> = data => {
        setCarregando(true);
        submit(data);
    }

    function submit(data: Usuario) {
        data.senha = criptografar(data.senha);
        data.administrador = Boolean(data.administrador);
        props.match.params.id
            ? editar(data)
            : salvar(data);
    }

    function salvar(data: Usuario) {
        api.post('/Usuario', data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function editar(data: Usuario) {
        api.put('/Usuario', data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function informarSucesso() {
        setMessagePrimary(['Usuário salvo com sucesso.']);
        setShowPrimary(true);
    }

    function tratarErro(error: AxiosError<ApiError>) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, 'Não foi possível salvar o usuário: '));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <form onSubmit={handleSubmit(onSubmit)} className='rxlib-form'>
                    <div className='container-fluid'>
                        <div className='row px-1'>
                            <div className='col-12'>
                                <h6>{props.match.params.action ? 'Visualizar' : props.match.params.id ? 'Editar' : 'Novo'} usuário</h6>
                            </div>
                        </div>
                        {
                            props.match.params.id
                                ? <div className='row px-1'>
                                    <div className='col-12 mt-1'>
                                        <InputLabel
                                            name='id'
                                            type='text'
                                            id='inputId'
                                            maxLength={50}
                                            readOnly={true}
                                            label='Código:'
                                            autoFocus={false}
                                            defaultValue={usuario.id}
                                            placeholder='Código do usuário'
                                            referencia={register({ required: true })} />
                                    </div>
                                </div>
                                : ''
                        }
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    name='nome'
                                    type='text'
                                    label='Nome:'
                                    id='inputNome'
                                    maxLength={50}
                                    autoFocus={true}
                                    defaultValue={usuario.nome}
                                    placeholder='Nome do usuário'
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    name='email'
                                    type='email'
                                    id='inputEmail'
                                    label='E-mail:'
                                    maxLength={100}
                                    autoFocus={false}
                                    defaultValue={usuario.email}
                                    placeholder='E-mail do usuário'
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    name='senha'
                                    label='Senha:'
                                    maxLength={50}
                                    id='inputSenha'
                                    type='password'
                                    autoFocus={false}
                                    defaultValue={usuario.senha}
                                    placeholder='Senha do usuário'
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <SelectLabelAsync
                                    foco='nao'
                                    type='Licenciada'
                                    name='idLicenciada'
                                    label='Licenciada:'
                                    id='inputIdLicenciada'
                                    action={props.match.params.action}
                                    valorSelecionado={usuario.idLicenciada}
                                    referencia={register({ required: true })} />
                            </div>
                        </div>
                        <div className='row px-1 pt-1'>
                            <div className='col-12 mt-2'>
                                <Checkbox
                                    text='Administrador'
                                    name='administrador'
                                    referencia={register}
                                    value={String(usuario.administrador)}
                                    defaultChecked={usuario.administrador}
                                    disabled={props.match.params.action === 'view'} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-2'>
                                <label className='form-label'>Perfis:</label>
                                <ul className='list-group'>
                                    {
                                        perfis.map((item, index) =>
                                            <li key={index} className='list-group-item list-group-item-caronte'>
                                                <Checkbox
                                                    value={item.id}
                                                    text={item.nome}
                                                    referencia={register}
                                                    name={'perfis.' + index}
                                                    disabled={props.match.params.action === 'view'}
                                                    defaultChecked={!!perfisUsuario.find(valor => valor.idPerfil === item.id)} />
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <ButtonsCrud
                            carregando={carregando}
                            styleButton='btn-rxlib'
                            linkCancelarVoltar='/usuario'
                            visualizar={props.match.params.action === 'view'} />
                    </div>
                </form>
                <ModalWarning
                    show={showWarning}
                    message={messageWarning}
                    onHide={handleHideWarning} />
                <ModalPrimary
                    show={showPrimary}
                    message={messagePrimary}
                    onHide={handleHidePrimary} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <Redirecionar
                    se={salvo}
                    para='/usuario' />
            </RxlibLayout>
        </>
    );
}

export default UsuarioCadastro;