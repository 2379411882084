import { AxiosError } from 'axios';
import api from '../../../services/api';
import { useState, useEffect } from 'react';
import ProdutoLicenciadaExclusao from '../exclusao';
import { useAppSelector } from '../../../store/hooks';
import { tratarErroApi } from '../../../rxlib/services/utilitarios';
import { Listagem } from '../../../rxlib/components/layout/listagem';
import { ConfiguracoesTabela } from '../../../rxlib/components/table';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';
import { ApiError, ProdutoLicenciadaListagemProps } from '../../../services/tipos';

function ProdutoLicenciadaListagem(props: ProdutoLicenciadaListagemProps) {
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [nomeLicenciada, setNomeLicenciada] = useState<string>('');
    const [showExclusao, setShowExclusao] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [produtoLicenciada, setLicencasProdutos] = useState<[{}]>([{}]);
    const [realizarConsulta, setRealizarConsulta] = useState<boolean>(true);
    const [idProdutoLicenciada, setIdProdutoLicenciada] = useState<string>('');

    const handleHide = () => setShowWarning(false);

    const handleHideExclusao = () => setShowExclusao(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Licenciadas', link: '/licenciada' },
        { texto: 'Produtos da licenciada', link: '' },
    ];

    const configuracoesTabela: ConfiguracoesTabela = {
        mensagemPadrao: 'Não foram encontrados resultados para a consulta.',
        colunas: [
            { nome: 'Produto', campo: 'Nome', entidadePai: 'Produto', tipo: 'string' },
            { nome: 'Expira em', campo: 'ExpiraEm', tipo: 'date' },
        ],
    }

    const temPermissao = usuarioTemPermissao(token, 'produto_licenciada_visualizar');
    const linkNovo = usuarioTemPermissao(token, 'produto_licenciada_novo') ? `/licenciada/produtolicenciada/${props.match.params.idLicenciada}/novo` : '';
    const linkEdicao = usuarioTemPermissao(token, 'produto_licenciada_editar') ? `/licenciada/produtolicenciada/${props.match.params.idLicenciada}/editar` : '';
    const handleExclusao = usuarioTemPermissao(token, 'produto_licenciada_excluir') ? handleExcluir : undefined;

    useEffect(() => {
        if ((temPermissao) && (realizarConsulta)) {
            setCarregando(true);
            Promise.all([
                api.get(`/Licenciada/${props.match.params.idLicenciada}`),
                api.get(`/OData/ProdutoLicenciada?$filter=idLicenciada eq ${props.match.params.idLicenciada}&$expand=Licenciada,Produto`)
            ]).then(response => {
                setTimeout(() => {
                    setLicencasProdutos(response[1].data.value);
                    setNomeLicenciada(response[0].data.nome);
                    setRealizarConsulta(false);
                    setCarregando(false);
                }, 250);
            }).catch((error: AxiosError<ApiError>) => {
                setCarregando(false);
                setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                setShowWarning(true);
            });
        }
    }, [props.match.params.idLicenciada, temPermissao, realizarConsulta]);

    function handleExcluir(id: string) {
        setIdProdutoLicenciada(id);
        setShowExclusao(true);
    }

    function tratarErro(error: AxiosError<ApiError>, mensagem: string) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, mensagem));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <Listagem
                    campoId='Id'
                    linkNovo={linkNovo}
                    tipoBotaoAcao='button'
                    listagemDetalhe={false}
                    carregando={carregando}
                    linkEdicao={linkEdicao}
                    onExcluir={handleExclusao}
                    fonteDados={produtoLicenciada}
                    configuracoesTabela={configuracoesTabela}
                    descricao={`Listagem dos produtos da licenciada: ${nomeLicenciada}`}
                    linkVisualizacao={`/licenciada/produtolicenciada/${props.match.params.idLicenciada}/visualizar`} />
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <ProdutoLicenciadaExclusao
                    show={showExclusao}
                    tratarErro={tratarErro}
                    onHide={handleHideExclusao}
                    onExcluir={setRealizarConsulta}
                    idProdutoLicenciada={idProdutoLicenciada} />
            </RxlibLayout>
        </>
    );
}

export default ProdutoLicenciadaListagem;