import { AxiosError } from 'axios';
import api from '../../../services/api';
import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { useForm, SubmitHandler } from 'react-hook-form';
import InputLabel from '../../../rxlib/components/input-label';
import Redirecionar from '../../../rxlib/components/redirecionar';
import { usuarioTemPermissao } from '../../../rxlib/services/seguranca';
import AcessoBloqueado from '../../../rxlib/components/acesso-bloqueado';
import ButtonsCrud from '../../../rxlib/components/buttons/buttons-crud';
import { RxlibLayout } from '../../../rxlib/components/layout/rxlib-Layout';
import { ModalWarning } from '../../../rxlib/components/modal/modal-warning';
import { ModalPrimary } from '../../../rxlib/components/modal/modal-primary';
import Breadcrumb, { BreadcrumbItem } from '../../../rxlib/components/breadcrumb';
import { SelectLabelAsync } from '../../../rxlib/components/select/select-label-async';
import { formatarDataParaComponente, tratarErroApi } from '../../../rxlib/services/utilitarios';
import { ApiError, ProdutoLicenciada, ProdutoLicenciadaCadastroProps } from '../../../services/tipos';

function ProdutoLicenciadaCadastro(props: ProdutoLicenciadaCadastroProps) {
    const [salvo, setSalvo] = useState<boolean>(false);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showPrimary, setShowPrimary] = useState<boolean>(false);
    const [token] = useState(useAppSelector(state => state.token));
    const [nomeLicenciada, setNomeLicenciada] = useState<string>('');
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [messagePrimary, setMessagePrimary] = useState<string[]>([]);

    const [produtoLicenciada, setProdutoLicenciada] = useState<ProdutoLicenciada>({
        id: '',
        expiraEm: '',
        idProduto: '',
        idLicenciada: '',
    });

    const { register, handleSubmit } = useForm<ProdutoLicenciada>();

    const handleHideWarning = () => setShowWarning(false);

    const handleHidePrimary = () => {
        setShowPrimary(false);
        setSalvo(true);
    }

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: 'Home', link: '/home' },
        { texto: 'Produtos da licenciada', link: `/licenciada/produtolicenciada/${props.match.params.idLicenciada}` },
        { texto: props.match.params.action ? 'Visualização' : props.match.params.id ? 'Edição' : 'Novo', link: '' },
    ];

    const temPermissao = props.match.params.action
        ? usuarioTemPermissao(token, 'produto_licenciada_visualizar')
        : props.match.params.id
            ? usuarioTemPermissao(token, 'produto_licenciada_editar')
            : usuarioTemPermissao(token, 'produto_licenciada_novo');

    useEffect(() => {
        if (temPermissao) {
            if (props.match.params.id) {
                api.get(`/ProdutoLicenciada/${props.match.params.id}`)
                    .then(response => {
                        setProdutoLicenciada({
                            id: response.data.id,
                            expiraEm: response.data.expiraEm,
                            idProduto: response.data.idProduto,
                            idLicenciada: response.data.idLicenciada,
                        });
                    }).catch((error: AxiosError<ApiError>) => {
                        tratarErro(error);
                    });
            }

            api.get(`/Licenciada/${props.match.params.idLicenciada}`)
                .then(response => {
                    setNomeLicenciada(response.data.nome);
                }).catch((error: AxiosError<ApiError>) => {
                    tratarErro(error);
                });
        }
    }, [temPermissao, props.match.params.id, props.match.params.idLicenciada]);

    const onSubmit: SubmitHandler<ProdutoLicenciada> = data => {
        setCarregando(true);
        props.match.params.id
            ? editar(data)
            : salvar(data);
    }

    function salvar(data: ProdutoLicenciada) {
        api.post('/ProdutoLicenciada', data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function editar(data: ProdutoLicenciada) {
        api.put('/ProdutoLicenciada', data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function informarSucesso() {
        setMessagePrimary(['Produto da licenciada salvo com sucesso.']);
        setShowPrimary(true);
    }

    function tratarErro(error: AxiosError<ApiError>) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, 'Não foi possível salvar o produto da licenciada: '));
        setShowWarning(true);
    }

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <form onSubmit={handleSubmit(onSubmit)} className='rxlib-form'>
                    <div className='container-fluid'>
                        <div className='row px-1'>
                            <div className='col-12'>
                                <h6>{props.match.params.action ? 'Visualizar' : props.match.params.id ? 'Editar' : 'Novo'} produto da licenciada</h6>
                            </div>
                        </div>
                        {
                            props.match.params.id
                                ? <div className='row px-1'>
                                    <div className='col-12 mt-1'>
                                        <InputLabel
                                            name='id'
                                            type='text'
                                            id='inputId'
                                            maxLength={50}
                                            readOnly={true}
                                            label='Código:'
                                            autoFocus={false}
                                            defaultValue={produtoLicenciada.id}
                                            referencia={register({ required: true })}
                                            placeholder='Código do produto da licenciada' />
                                    </div>
                                </div>
                                : ''
                        }
                        <div className='row px-1 d-none'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    type='text'
                                    maxLength={50}
                                    readOnly={true}
                                    autoFocus={false}
                                    name='idLicenciada'
                                    id='inputIdLicenciada'
                                    label='Código da Licenciada:'
                                    placeholder='Código da Licenciada'
                                    referencia={register({ required: true })}
                                    defaultValue={props.match.params.idLicenciada} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    type='text'
                                    maxLength={50}
                                    readOnly={true}
                                    autoFocus={false}
                                    name='licenciada'
                                    label='Licenciada:'
                                    id='inputLicenciada'
                                    placeholder='Licenciada'
                                    defaultValue={nomeLicenciada}
                                    referencia={React.createRef()} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <SelectLabelAsync
                                    foco='nao'
                                    type='Produto'
                                    name='idProduto'
                                    label='Produto:'
                                    id='inputIdProduto'
                                    action={props.match.params.action}
                                    referencia={register({ required: true })}
                                    valorSelecionado={produtoLicenciada.idProduto} />
                            </div>
                        </div>
                        <div className='row px-1'>
                            <div className='col-12 mt-1'>
                                <InputLabel
                                    type='date'
                                    maxLength={10}
                                    name='expiraEm'
                                    autoFocus={false}
                                    label='Expira em:'
                                    id='inputExpiraEm'
                                    placeholder='  /  /    '
                                    referencia={register({ required: true })}
                                    readOnly={props.match.params.action === 'view'}
                                    defaultValue={formatarDataParaComponente(produtoLicenciada.expiraEm)} />
                            </div>
                        </div>
                        <ButtonsCrud
                            carregando={carregando}
                            styleButton='btn-rxlib'
                            linkCancelarVoltar={`/licenciada/produtolicenciada/${props.match.params.idLicenciada}`}
                            visualizar={props.match.params.action === 'view'} />
                    </div>
                </form>
                <ModalWarning
                    show={showWarning}
                    message={messageWarning}
                    onHide={handleHideWarning} />
                <ModalPrimary
                    show={showPrimary}
                    message={messagePrimary}
                    onHide={handleHidePrimary} />
                <AcessoBloqueado
                    permissao={temPermissao} />
                <Redirecionar
                    se={salvo}
                    para={`/licenciada/produtolicenciada/${props.match.params.idLicenciada}`} />
            </RxlibLayout>
        </>
    );
}

export default ProdutoLicenciadaCadastro;