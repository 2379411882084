import { AxiosError } from 'axios';
import { Modal } from 'react-bootstrap';
import api from '../../../services/api';
import { useEffect, useState } from 'react';
import Spinner from '../../../rxlib/components/spinner';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ApiError, FormExclusao } from '../../../services/tipos';
import { Exclusao } from '../../../rxlib/components/layout/exclusao';

interface VersaoAtualProdutoExclusaoProps {
    show: boolean;
    onHide: () => void;
    idVersaoAtualProduto: string;
    onExcluir: (valor: boolean) => void;
    tratarErro: (error: AxiosError<ApiError>, mensagem: string) => void;
}

function VersaoAtualProdutoExclusao(props: VersaoAtualProdutoExclusaoProps) {
    const [versao, setVersao] = useState<string>('');
    const [produto, setProduto] = useState<string>('');
    const [carregando, setCarregando] = useState<boolean>(false);
    const [carregandoVersaoAtualProduto, setCarregandoVersaoAtualProduto] = useState<boolean>(false);

    const { handleSubmit } = useForm<FormExclusao>();

    useEffect(() => {
        if (props.show) {
            setCarregandoVersaoAtualProduto(true);
            api.get(`/OData/VersaoAtualProduto?$expand=Produto&$filter=id eq ${props.idVersaoAtualProduto}`)
                .then(response => {
                    setVersao(response.data.value[0].VersaoPrincipal + '.' +
                        response.data.value[0].VersaoMenor + '.' +
                        response.data.value[0].Build + '.' +
                        response.data.value[0].Release);

                    setProduto(response.data.value[0].Produto.Nome);
                    setCarregandoVersaoAtualProduto(false);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregandoVersaoAtualProduto(false);
                    props.tratarErro(error, 'Não foi possível realizar a consulta: ');
                });
        }
    }, [props]);

    const onSubmit: SubmitHandler<FormExclusao> = data => {
        setCarregando(true);
        api.delete(`/VersaoAtualProduto/${props.idVersaoAtualProduto}`, data)
            .then(response => {
                informarSucesso();
            }).catch((error: AxiosError<ApiError>) => {
                setCarregando(false);
                props.tratarErro(error, `Não foi possível excluir a versão ${versao} do produto ${produto}: `);
            });
    }

    function informarSucesso() {
        props.onHide();
        props.onExcluir(true);
        setCarregando(false);
    }

    return (
        <Modal show={props.show} onHide={props.onHide} dialogClassName='rxlib-modal-exclusao' centered>
            <Modal.Body className='rxlib-modal-exclusao-body'>
                {
                    carregandoVersaoAtualProduto
                        ? <Spinner
                            classStyle='rxlib-spinner' />
                        : <Exclusao
                            carregando={carregando}
                            onCancelar={props.onHide}
                            onSubmit={handleSubmit(onSubmit)}
                            titulo={`Deseja realmente excluir a versão ${versao} do produto ${produto}?`} />
                }
            </Modal.Body>
        </Modal>
    );
}

export default VersaoAtualProdutoExclusao;